<template>
	<div>
		<match-page-tennis></match-page-tennis>
	</div>
</template>

<script>
	import MatchPageTennis from "../components/Sports/Tennis/Pages/MatchPage";
	
	export default {
		components: {
			MatchPageTennis,
		}
	}
</script>

<style scoped>

</style>