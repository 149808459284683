<template>
	<div class="match-data_row">
		<div class="match-data_col-1 __red" v-if="match.timer">
			{{ match.timer }}'
		</div>
		<div class="match-data_col-1" v-else-if="match.status === 'Not Started'">
			{{ match.time }}
		</div>
		<div class="match-data_col-1" v-else-if="match.status">
			{{ getStatus(match.status) }}
		</div>
		
		<div class="match-data_col-2" :class="{__bold: bold(match.home_score, match.away_score)}">
			<img :src="$hostname + '/goalserve/soccer/team_image/' + match.home_id" alt="">
			<span v-text="match.home_name"></span>
		</div>
		<div class="match-data_col-3">
			{{ match.home_score !== '?' ? match.home_score: '' }}
		</div>
		<div class="match-data_col-4">-</div>
		<div class="match-data_col-5">
			{{ match.away_score !== '?' ? match.away_score: '' }}
		</div>
		<div class="match-data_col-6" :class="{__bold: bold(match.away_score, match.home_score)}">
			<span v-text="match.away_name"></span>
			<img :src="$hostname + '/goalserve/soccer/team_image/' + match.away_id" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		props: ['match', 'showDate'],
		
		methods: {
			bold(team1, team2) {
				if (isNaN(parseInt(team1)) || isNaN(parseInt(team2)))
					return false;
				
				return parseInt(team1) > parseInt(team2);
			},
			getStatus(status) {
				let data = {
					'Finished': 'Завершен',
					'Not Started': 'Не начался',
					'Cancelled': 'Отменен',
				};
				
				return data[status] ? data[status] : status;
			}
		}
	}
</script>

<style scoped>

</style>