<template>
	<div v-if="matches && Object.keys(matches).length">
		<div class="block">
			<div class="wrapper">
				<div>
					<div class="header-block">
						<h2 class="header-block_title" v-if="title">{{ title }}</h2>
						
						<router-link :to="{name: 'live-sport', params: {sport: slug}}" class="header-block_link">
							Все матчи
						</router-link>
					</div>
					
					<links v-if="matches" sport="hockey" :data="matches"></links>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import _ from 'underscore';
	import Links from "../Sports/Hockey/Components/Events/Links";
	
	export default {
		components: {
			Links
		},
		
		props: ['matches', 'title', 'slug'],
		
		data() {
			return {
				slickOptions: {
					prevArrow: '<div class="ico icon-side-arrow slick-prev"></div>',
					nextArrow: '<div class="ico icon-side-arrow slick-next"></div>',
				},
			}
		},
		
		computed: {
			matchesMobile() {
				return this.getMatchesMobile();
			}
		},
		
		methods: {
			getTime(date) {
				return moment(date).format('HH:mm');
			},
			getMatchesMobile() {
				let matches = [];
				
				_.each(this.matches, (match, matchName) => {
					_.each(match.events, (event) => {
						event.league_name = match.league_name;
						matches.push(event);
					});
				});
				
				return matches;
			},
			goToMatch(id) {
				this.$router.push({
					name: 'event',
					params: {
						id: id
					}
				});
			},
			boldIfWinner(team1, team2, status) {
				if (Number(team1) > Number(team2) && status === 'finished') {
					return ['__bold'];
				}
				return [];
			}
		}
	}
</script>