<template>
	<div>
		<div class="main">
			<div class="wrapper">
				<router-link :to="{name: 'league', params: {id: 4529, sport: 'soccer'}}" class="main_content" style="height: 350px;background-image: url(/img/main-banner.png); background-position: right center">
					<div class="main_text">
						<!--<div class="main_heading">ВАШ ПУТЕВОДИТЕЛЬ<br>В МИРЕ СПОРТА</div>-->
					</div>
				</router-link>
			</div>
		</div>
		
		<div v-if="type === 'main'" class="main-banner">
			<div class="main-banner_video">
				<video autoplay playsinline :src="active.videoUrl" loop muted></video>
			</div>
			<div class="main-banner_content">
				<div class="main-banner_title">{{ active.title }}</div>
				<!--<router-link :to="active.route" class="main-banner_button">{{ active.button }}</router-link>-->
			</div>
		</div>
		<div class="main-mobile-block" v-else>
			<img src="/img/main-mobile-champion.jpg" alt="">
		</div>
		
		<!--<div class="main-mobile-simple">
			<video autoplay :src="'/video/' +  getVideo()" loop muted></video>
		</div>-->
		
		<!--<a href="https://w.kassa.rambler.ru/event/53121263/233f47e9-c7bb-4077-8020-5d2679af1e8f/%2F/" class="main-mobile" v-else>
			<video autoplay :src="'/video/main.mp4'" loop muted></video>
			
			<div class="wrapper">
				<div class="main-mobile_row">
					<div class="main-mobile_image">
						<img width="90" src="/img/logo-1.png" alt="">
					</div>
					
					<div class="main-mobile_text" style="text-shadow: 0 0 2px #000">VS</div>
					
					<div class="main-mobile_image">
						<img width="75" src="/img/logo-2.png" alt="">
					</div>
				</div>
				
				<div class="main-mobile_time" style="text-shadow: 0 0 2px #000">среда, 4 марта в 19:30</div>
				
				<button class="main-mobile_button">Купить билет</button>
			</div>
		</a>-->
	</div>
</template>

<script>
	export default {
		props: ['type'],
		
		data() {
			return {
				active: null
			}
		},
		
		created() {
			this.active = this.getVideo();
		},
		
		methods: {
			getVideo() {
				let data = [
					{
						name: 'football',
						videoUrl: '/video/main/football.mp4',
						title: 'Мировой футбол у Вас в мобильном!',
						button: 'Смотреть',
						route: {
							name: 'live-sport',
							params: {sport: 'soccer'}
						}
					},
					{
						name: 'hockey',
						videoUrl: '/video/main/hockey.mp4',
						title: 'Хоккейные матчи онлайн!',
						button: 'Смотреть',
						route: {
							name: 'live-sport',
							params: {sport: 'hockey'}
						}
					},
					{
						name: 'tennis',
						videoUrl: '/video/main/tennis.mp4',
						title: 'Все турниры большого шлема онлайн!',
						button: 'Смотреть',
						route: {
							name: 'live-sport',
							params: {sport: 'tennis'}
						}
					},
					{
						name: 'goal',
						videoUrl: '/video/main/goal.mp4',
						title: 'Будь в игре!',
						button: 'Билеты на спорт',
						route: {
							name: 'tickets'
						}
					},
				];
				
				let random = this.getRandomInt(data.length);
				
				localStorage.setItem('videoNum', random);
				
				return data[random];
			},
			getRandomInt(max) {
				let random = Math.floor(Math.random() * Math.floor(max));
				
				if (random == localStorage.getItem('videoNum')) {
					return this.getRandomInt(max);
				}
				
				return random;
			}
		}
	}
</script>
