<template>
	<div>
		<div class="last-result-data">
			<tab :headings="{total: 'Итого', home: 'Дома', away: 'В гостях'}" active-key="total">
				<template v-slot:total>
					<last-result-data :home="home" :away="away" :lastResults="lastResults"></last-result-data>
				</template>
				<template v-slot:home>
					<last-result-data :home="home" :away="away" :lastResults="homeResults" only-team="home"></last-result-data>
				</template>
				<template v-slot:away>
					<last-result-data :home="home" :away="away" :lastResults="awayResults" only-team="away"></last-result-data>
				</template>
			</tab>
		</div>
	</div>
</template>

<script>
	import LastResultData from "./LastResultData";
	import Tab from "../../../../Tab/Tab";

	export default {
		components: {
			LastResultData,
			Tab
		},

		props: ['match', 'results'],

		data() {
			return {
				res: this.results
			}
		},
		computed: {
			home() {
				if (!this.match.localteam) {
					return {
						id: this.match.home_id,
						name: this.match.home_name,
					}
				}

				return this.match.localteam;
			},
			away() {
				if (!this.match.visitorteam) {
					return {
						id: this.match.away_id,
						name: this.match.away_name,
					}
				}

				return this.match.visitorteam;
			},
			lastResults() {
				let res = JSON.parse(JSON.stringify(this.results));

				let data = {
					team1: [],
					team2: []
				};

				for (let i = 0; i <= 4; i++) {
					res.last5_home.team1.match[i].home = true;
					data.team1.push(res.last5_home.team1.match[i]);

					res.last5_away.team1.match[i].away = true;
					data.team1.push(res.last5_away.team1.match[i]);


					res.last5_home.team2.match[i].home = true;
					data.team2.push(res.last5_home.team2.match[i]);

					res.last5_away.team2.match[i].away = true;
					data.team2.push(res.last5_away.team2.match[i]);
				}

				return this.sortResults(data);
			},
			homeResults() {
				let data = {
					team1: this.res.last5_home.team1.match,
					team2: this.res.last5_home.team2.match,
				}

				return this.sortResults(data);
			},
			awayResults() {
				let res = JSON.parse(JSON.stringify(this.results));

				let data = {
					team1: [],
					team2: []
				};

				for (let i = 0; i <= 4; i++) {
					res.last5_away.team1.match[i].away = true;
					data.team1.push(res.last5_away.team1.match[i]);

					res.last5_away.team2.match[i].away = true;
					data.team2.push(res.last5_away.team2.match[i]);
				}

				return this.sortResults(data);
			},
		},
		methods: {
			sortResults(data) {
				return {
					team1: data.team1.sort((a, b) => {
						return new Date(this.getDate(b.date)) - new Date(this.getDate(a.date));
					}),
					team2: data.team2.sort((a, b) => {
						return new Date(this.getDate(b.date)) - new Date(this.getDate(a.date));
					}),
				}
			},
			getDate(date) {
				date = date.split('.');
				return date[2] + '-' + date[1] + '-' + date[0];
			}
		}
	}
</script>