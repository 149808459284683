<template>
	<div v-if="matches && Object.keys(matches).length">
		<div class="block">
			<div class="wrapper">
				<div class="desktop">
					<div class="header-block">
						<h2 class="header-block_title" v-if="title">{{ title }}</h2>
						
						<router-link :to="{name: 'live-sport', params: {sport: slug}}" class="header-block_link">
							Все матчи
						</router-link>
					</div>
					
					<links v-if="matches" sport="soccer" :data="matches"></links>
				</div>
				
				<div class="mobile">
					<div class="header-block">
						<h2 class="header-block_title" v-if="title">{{ title }}</h2>
						
						<router-link :to="{name: 'live-sport', params: {sport: slug}}" class="header-block_link">
							Все матчи
						</router-link>
					</div>
					
					<div class="match-block-mobile">
						<slick v-if="matches" :options="slickOptions">
							<div v-for="event in matchesMobile" :key="event.id">
								<div class="match-block-mobile_header">
									{{ event.league_name }}
								</div>
								
								<router-link :to="{name: 'event', params: {id: event.id}}" class="match-block-mobile_row">
									<div class="match-block-mobile_command">
										<div class="match-block-mobile_image">
											<img :src="$hostname + '/goalserve/soccer/team_image/' + event.home_id" alt="">
										</div>
										
										<div class="match-block-mobile_text">{{ event.home_name }}</div>
									</div>
									
									<div class="match-block-mobile_result">
										<div v-if="event.home_score === '?'" class="match-block-mobile_cnt __min">
											Матч не начался
										</div>
										<div class="match-block-mobile_cnt" v-else>
											<span>{{ event.home_score }}</span>
											<span> : </span>
											<span>{{ event.away_score }}</span>
										</div>
										<div class="match-block-mobile_status">
											<div class="match-block-mobile_date">
												<div>{{ event.time }}</div>
											</div>
										</div>
									</div>
									
									<div class="match-block-mobile_command">
										<div class="match-block-mobile_image">
											<img :src="$hostname + '/goalserve/soccer/team_image/' + event.away_id" alt="">
										</div>
										
										<div class="match-block-mobile_text">{{ event.away_name }}</div>
									</div>
								</router-link>
							</div>
						</slick>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import _ from 'underscore';
	
	import Slick from 'vue-slick';
	import Links from "../Sports/Soccer/Components/Events/Links";
	
	export default {
		components: {
			Slick,
			Links
		},
		
		props: ['matches', 'title', 'slug'],
		
		data() {
			return {
				slickOptions: {
					prevArrow: '<div class="ico icon-side-arrow slick-prev"></div>',
					nextArrow: '<div class="ico icon-side-arrow slick-next"></div>',
				},
			}
		},
		
		computed: {
			matchesMobile() {
				return this.getMatchesMobile();
			}
		},
		
		methods: {
			getTime(date) {
				return moment(date).format('HH:mm');
			},
			getMatchesMobile() {
				let matches = [];
				
				_.each(this.matches, (match, matchName) => {
					_.each(match.events, (event) => {
						event.league_name = match.league_name;
						matches.push(event);
					});
				});
				
				return matches;
			},
			goToMatch(id) {
				this.$router.push({
					name: 'event',
					params: {
						id: id
					}
				});
			},
			boldIfWinner(team1, team2, status) {
				if (Number(team1) > Number(team2) && status === 'finished') {
					return ['__bold'];
				}
				return [];
			}
		}
	}
</script>