import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeBase from '../views/HomeBase'
import Home from '../views/Home'

import LiveSport from '../views/LiveSport'

import League from '../views/League'
import TennisLeague from "../views/TennisLeague";

import TennisRank from "../views/TennisRank";

import Match from '../views/Match'
import MatchTennis from '../views/MatchTennis'
import MatchHockeyResult from "../views/MatchHockeyResult";
import MatchMma from "../views/MatchMma";

import Team from '../views/Team'
import TeamHockey from '../views/TeamHockey'

import Player from "../views/Player";

import News from '../views/News.vue'
import NewsItem from '../views/NewsItem'

import Articles from '../views/Articles'
import Article from '../views/Article'

import Videos from '../views/Videos'
import Video from '../views/Video'

import Games from '../views/Games'

import Tickets from "../views/Tickets";

import Subscribe from "../views/Subscribe";
import Logout from "../views/Logout";
import NoMoney from "../views/NoMoney";

import NotFound from "../views/NotFound";
import Restrict from "../views/Restrict";

Vue.use(VueRouter);

const routes = [
	// Live
	{path: '/live', redirect: { name: 'live-sport', params: {sport: 'soccer'} }},
	{path: '/stat/:sport', name: 'live-sport', component: LiveSport},
	
	// League
	{path: '/league/:sport/:id', name: 'league', component: League},
	{path: '/category/:sport/:type', name: 'league-tennis', component: TennisLeague},
	
	// Tennis rank
	{path: '/rank/tennis/:type', name: 'tennis-rank', component: TennisRank},
	
	// Match Result Hockey
	{path: '/match/hockey/:league/:id', name: 'match-hockey', component: MatchHockeyResult},
	
	// Match Tennis
	{path: '/event/tennis/:id', name: 'event-tennis', component: MatchTennis},
	
	// Match MMA
	{path: '/event/mma/:id', name: 'event-mma', component: MatchMma},
	
	// Match
	{path: '/event/:sport/:league/:id', name: 'event', component: Match},
	
	// Team
	{path: '/team/hockey/:league/:id', name: 'team-hockey', component: TeamHockey},
	{path: '/team/:sport/:id', name: 'team', component: Team},
	
	// Player
	{ path: '/player/:sport/:id', name: 'player', component: Player },
	
	// News
	{path: '/news', name: 'news', component: News},
	{path: '/news/:id', name: 'news-item', component: NewsItem},
	
	// Articles
	{path: '/articles', name: 'articles', component: Articles},
	{path: '/articles/:id', name: 'article', component: Article},
	
	// Videos
	{path: '/videos', name: 'videos', component: Videos},
	{path: '/videos/:id', name: 'video', component: Video},
	
	// Games
	{path: '/games', name: 'games', component: Games},
	
	// Tickets
	//{ path: '/tickets', name: 'tickets', component: Tickets },
	
	// Formula
	/*
	{ path: '/formula', name: 'formula', component:  Formula },
	{ path: '/formula/command/:id', name: 'formula-command', component:  FormulaCommand },
	{ path: '/formula/pilot/:id', name: 'formula-pilot', component:  FormulaPilot },
	{ path: '/formula/track/:id', name: 'formula-circuit', component:  FormulaTrack },
	*/
	
	// Subscribe
	{ path: '/subscribe', name: 'subscribe', component: Subscribe },
	{ path: '/no-money', name: 'no-money', component: NoMoney },
	{ path: '/restricted', name: 'restricted', component: Restrict },

	{ path: '/404', name: '404', component: NotFound },
	
	// Home
	{path: '/:sport', name: 'home', component: Home},
	{path: '/', name: 'home-base', component: HomeBase},
	{ path: '*', redirect: '/404' },
];

const router = new VueRouter({
	routes,
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		window.events.$emit('page-changed');
		
		$('html, body').animate({
			scrollTop: 0
		}, 400);
	}
});
router.beforeEach((to, from, next) => {
	if (Vue.config.local) {
		fake(to, from, next);
		return;
	}

	if (Vue.config.local || to.name === 'logout') {
		next(); return;
	}

	if (to.name === 'home-base') {
		next(); return;
	}

	axios.get('/a1/profile').then(({data}) => {
		if(!data.msisdn) {
			events.$emit('not-logged');
		} else {
			window.auth = true;
			events.$emit('auth');
			
			if (data.status === 'error') {
				window.error.error = true;
				next(); return;
			}

			if (data.status === 'not_subscribed') {
				if (to.name === 'subscribe' || to.name === 'home-base') {
					next(); return;
				} else {
					location.href = '/subscribe';return;
				}
			} else if (data.status === 'subscribed' || data.status === 'allowed') {
				window.subscribed = true;
				events.$emit('subscribed');

				if (to.name === 'subscribe') {
					location.href = '/'; return;
				} else {
					next(); return;
				}
			} else if (data.status === 'restricted') {
				if (to.name === 'restricted' || to.name === 'home-base') {
					next(); return;
				} else {
					location.href = '/restricted'; return;
				}
			}
		}

		if (to.name === 'home-base') {
			next();
		}
	});
});

function fake(to, from, next) {
	var data = {
		msisdn: '455',
		status: 'restricted'
	};

	if (to.name === 'home-base') {
		next(); return;
	}
	
	if(!data.msisdn) {
		events.$emit('not-logged');
	} else {
		window.auth = true;
		events.$emit('auth');

		if (data.status === 'not_subscribed') {
			if (to.name === 'subscribe' || to.name === 'home-base') {
				next(); return;
			} else {
				location.href = '/subscribe';return;
			}
		} else if (data.status === 'subscribed' || data.status === 'allowed') {
			window.subscribed = true;
			events.$emit('subscribed');

			if (to.name === 'subscribe' || to.name === 'restricted') {
				location.href = '/'; return;
			} else {
				next(); return;
			}
		} else if (data.status === 'restricted') {
			if (to.name === 'restricted' || to.name === 'home-base') {
				next(); return;
			} else {
				location.href = '/restricted'; return;
			}
		}
	}
}


export default router
