<template>
	<!-- eslint-disable -->
	
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content">
				<div>
					<div class="match_item-header active">
						<span>Результаты</span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<router-link v-for="match in matches"
										 class="match-data_item" 
										 :to="{ name: 'event', params: {id: match.id, league: match.league_id, sport: 'bsktbl'} }">
								<link-bsktbl :match="match" :show-date="showDate"></link-bsktbl>
							</router-link>
						</div>
					</div>
				</div>
				<button v-if="matches && data[0].events.length > matches.length" class="match_btn" @click="page++">Показать еще</button>
			</div>
		</div>
	</div>
</template>

<script>
	import LinkBsktbl from "./Link";
	
	export default {
		components: {
			LinkBsktbl
		},
		
		props: ['data', 'showDate'],
		
		data() {
			return {
				page: 1,
			}
		},
		
		computed: {
			matches() {
				return this.data[0].events.slice(0, this.page * 10);
			},
		},
		
		methods: {
			getTitle(prefix, suffix) {
				let data = {
					'Tour': 'Тур',
					'Week': 'Тур',
					'Group Stage': 'Групповая стадия',
					'Play-offs': 'Плей-офф',
					'16th Finals': '1/16 финала',
					'8th Finals': '1/8 финала',
					'Quarter-finals': 'Четвертьфиналы',
					'Semi-finals': 'Полуфиналы',
					'Final': 'Финал',
					'3rd Qualifying Round': 'Квалификация',
					'2nd Qualifying Round': 'Квалификация',
					'1st Qualifying Round': 'Квалификация',
					'Preliminary Round': 'Предварительный раунд',
					'Promotion Play-offs - Final': 'Плей-офф, финал',
					'Матчи': 'Матчи',
				};
				
				data[prefix] = data[prefix] ? data[prefix]: '';
				
				return data[prefix] + ' ' + suffix;
			}
		}
	}
</script>

<style scoped>

</style>