<template>
	<div>
		<tennis-rank-page></tennis-rank-page>
	</div>
</template>

<script>
	import TennisRankPage from "../components/Sports/Tennis/Pages/TennisRankPage";
	
	export default {
		components: {
			TennisRankPage
		}
	}
</script>

<style scoped>

</style>