<template>
	<div class="match">
		<div class="wrapper">
			<div class="match_heading">События {{ activeDay.defaultValue }}</div>
			
			<div class="match_row">
				<div class="match_left">
					<rank-list></rank-list>
					
					<br>
				
					<leagues v-if="leagues" :data="leagues"></leagues>
				</div>
				
				<div class="match_right">
					<div class="tab">
						<match-header></match-header>
						
						<list :data="matchesData" :loading="loading"></list>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import RankList from "../Components/Events/RankList";
	import MatchHeader from "../../Common/MatchHeader";
	import List from "../Components/Events/List";
	import Leagues from "../Components/Events/Leagues";

	export default {
		components: {
			RankList,
			MatchHeader,
			List,
			Leagues
		},

		data() {
			return {
				sport: this.$route.params.sport,
				matchesData: null,
				activeDay: {},
				loading: false,
				leagues: null
			}
		},

		created() {
			this.initLeagues();
			
			this.loading = true;
			this.update();

			events.$on('activeDay', this.initDay);
		},

		methods: {
			initLeagues() {
				this.axios.get('/goalserve/tennis/league')
					.then(({data}) => {
						this.leagues = data.res;
					});
			},
			initData() {
				let day = this.$route.query.day_diff || 0;
				let url = '/goalserve/' + this.sport + '/league/events';

				if (day !== 0) {
					url += '?day_diff=' + day;
				}

				this.axios.get(url)
					.then(({data}) => {
						this.matchesData = data.res;
	
						this.loading = false;
					});
			},
			initDay(day) {
				this.activeDay = day;
			},
			update() {
				if (this.sport !== this.$route.params.sport) return;

				this.initData();

				setTimeout(() => {
					this.update();
				}, 1000 * 60);
			}
		}
	}
</script>

<style scoped>

</style>