<template>
	<div class="timeline">
		<div class="timeline-2">
			<div class="timeline-2_row __header">
				<div class="timeline-2_name"></div>
				
				<div class="timeline-2_score">С</div>
				
				<div class="timeline-2_value">1</div>
				<div class="timeline-2_value">2</div>
				<div class="timeline-2_value">3</div>
				<div class="timeline-2_value">4</div>
				<div class="timeline-2_value">5</div>
			</div>
			<div class="timeline-2_row">
				<div class="timeline-2_name" :class="isPlayer1Win()">{{ home.name }}</div>
				
				<div class="timeline-2_score" :class="isPlayer1Win()">{{ home.totalscore }}</div>
				
				<div class="timeline-2_value" v-for="value in 5" :class="isSetWin(home['s' + value], away['s' + value])" :key="'player-1' + value" >
					{{ parseInt(home['s' + value]) || '' }}
				</div>
			</div>
			<div class="timeline-2_row">
				<div class="timeline-2_name" :class="isPlayer2Win()">{{ away.name }}</div>
				
				<div class="timeline-2_score" :class="isPlayer2Win()">{{ away.totalscore }}</div>
				
				<div class="timeline-2_value" v-for="value in 5" :class="isSetWin(away['q' + value], home['q' + value])" :key="'player-1' + value" >
					{{ parseInt(away['q' + value]) || '' }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		props: ['match', 'sport'],
		
		computed: {
			home() {
				return this.match.home;
			},
			away() {
				return this.match.away;
			},
		},
		methods: {
			isPlayer1Win() {
				return {
					__bold: this.home.totalscore > this.away.totalscore
				}
			},
			isPlayer2Win() {
				return {
					__bold: this.away.totalscore > this.home.totalscore
				}
			},
			isSetWin(score1, score2) {
				return {
					__bold: Number(score1) > Number(score2)
				};
			}
		}
	}
</script>

<style scoped>

</style>