<template>
	<div class="live-statistic_item">
		<div class="live-statistic_row-3">
			<div class="live-statistic_col-2">
				<svg width="" height="" viewBox="0 0 172 172" xmlns="http://www.w3.org/2000/svg">
					<circle stroke="#CCC" stroke-width="12" fill="none" cx="86" cy="86" r="80"></circle>
					<circle stroke="#16559F" stroke-width="12" fill="none" cx="86" cy="86" r="80" stroke-dasharray="502.7,502.7" stroke-dashoffset="502.7" transform="rotate(-90 86 86)"></circle>
					<text font-weight="bold" id="svg_3" y="80" x="86" fill="#000000">Ноудауны</text>
					<text y="110" x="76" fill="#d20a0a">
						{{ home.knockdowns.total }}
					</text>
					<text y="110" x="86" fill="#000000">-</text>
					<text y="110" x="96" fill="#16559F">
						{{ away.knockdowns.total }}
					</text>
				  </svg>
			</div>
			<div class="live-statistic_col-2">
				<svg width="" height="" viewBox="0 0 172 172" xmlns="http://www.w3.org/2000/svg">
					<circle stroke="#CCC" stroke-width="12" fill="none" cx="86" cy="86" r="80"></circle>
					<circle stroke="#16559F" stroke-width="12" fill="none" cx="86" cy="86" r="80" stroke-dasharray="502.7,502.7" stroke-dashoffset="502.7" transform="rotate(-90 86 86)"></circle>
					<text font-weight="bold" id="svg_3" y="80" x="86" fill="#000000">Сабмишены</text>
					<text y="110" x="76" fill="#d20a0a">
						{{ home.submissions.total }}
					</text>
					<text y="110" x="86" fill="#000000">-</text>
					<text y="110" x="96" fill="#16559F">
						{{ away.submissions.total }}
					</text>
				  </svg>
			</div>
			<div class="live-statistic_col-2">
				<svg width="" height="" viewBox="0 0 172 172" xmlns="http://www.w3.org/2000/svg">
					<circle stroke="#CCC" stroke-width="12" fill="none" cx="86" cy="86" r="80"></circle>
					<circle stroke="#16559F" stroke-width="12" fill="none" cx="86" cy="86" r="80" stroke-dasharray="502.7,502.7" stroke-dashoffset="502.7" transform="rotate(-90 86 86)"></circle>
					<text font-weight="bold" id="svg_3" y="80" x="86" fill="#000000">Тейкдауны</text>
					<text y="110" x="76" fill="#d20a0a">
						{{ home.takedowns.landed }}
					</text>
					<text y="110" x="86" fill="#000000">-</text>
					<text y="110" x="96" fill="#16559F">
						{{ away.takedowns.landed }}
					</text>
				  </svg>
			</div>
		</div>
		
		<div class="live-statistic_row-4">
			<div class="live-statistic_col-3">
				<svg class="c-stat-body__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="268px" height="208px" viewBox="0 0 268 208">
					<g>
						<line fill="none" stroke="#EAEAEA" stroke-linecap="square" stroke-miterlimit="10" x1="0.5" y1="39.5" x2="266.14" y2="39.5"></line>
						
						<line fill="none" stroke="#EAEAEA" stroke-linecap="square" stroke-miterlimit="10" x1="0.5" y1="98.5" x2="266.14" y2="98.5"></line>
						
						<line fill="none" stroke="#EAEAEA" stroke-linecap="square" stroke-miterlimit="10" x1="0.5" y1="152.5" x2="266.14" y2="152.5"></line>
					</g>
					
					<g id="e-stat-body_x5F__x5F_head-txt">
						<text id="e-stat-body_x5F__x5F_head_percent" transform="matrix(1 0 0 1 230.8119 32)" fill="#D20A0A" font-size="14px">{{ homeOpacity.head }}%</text>
						<text id="e-stat-body_x5F__x5F_head_value" transform="matrix(1 0 0 1 197.8119 32)" fill="#D20A0A" font-size="14px">{{ home.strikes_power.head }}</text>
						<text transform="matrix(1 0 0 1 3.8119 33)" fill="#1C1C1C" font-weight="600" font-size="16px">Голова</text>
					</g>
					<g id="e-stat-body_x5F__x5F_body-txt">
						<text id="e-stat-body_x5F__x5F_body_percent" transform="matrix(1 0 0 1 226.8119 90)" fill="#D20A0A" font-size="14px">{{ homeOpacity.body }}%</text>
						<text id="e-stat-body_x5F__x5F_body_value" transform="matrix(1 0 0 1 197.8119 90)" fill="#D20A0A" font-size="14px">{{ home.strikes_power.body }}</text>
						<text transform="matrix(1 0 0 1 4.8119 91)" fill="#1C1C1C" font-weight="600" font-size="16px">Корпус</text>
					</g>
					<g id="e-stat-body_x5F__x5F_leg-txt">
						<text id="e-stat-body_x5F__x5F_leg_percent" transform="matrix(1 0 0 1 226.8119 145)" fill="#D20A0A" font-size="14px">{{ homeOpacity.legs }}%</text>
						<text id="e-stat-body_x5F__x5F_leg_value" transform="matrix(1 0 0 1 197.8119 145)" fill="#D20A0A" font-size="14px">{{ home.strikes_power.legs }}</text>
						<text transform="matrix(1 0 0 1 8.8119 146)" fill="#1C1C1C" font-weight="600" font-size="16px">Ноги</text>
					</g>
					
					<g id="e-stat-body_x5F__x5F_outline">
						<g>
							<path fill="#FAFAFA" stroke="#FAFAFA" stroke-width="6" stroke-miterlimit="10" d="M126.91,13.33
					c3.46,0.87,6.43,3.7,6.92,7.32c0.19,1.43,0.17,2.84,0.8,4.29c0.6,1.39,0.08,3.35-1.19,4.47c-2.01,1.78-1.83,4.14-2.2,6.36
					c-0.12,0.71,0.34,1.52,1.01,2.04c3.13,2.43,6.31,4.91,10.19,5.94c3.41,0.9,5.51,2.66,6.33,5.96c0.25,0.99,0.45,1.98,0.56,3
					c0.3,2.86,0.38,5.74,0.89,8.59c1.12,6.21,3,12.23,4.53,18.33c1.92,7.64,1.97,15.46,1.9,23.28c-0.03,2.69,0.23,5.33,1.16,7.89v1.44
					c-0.69,2.39-1.75,4.49-4.47,5.25c-1.14,0.32-2.23,0.25-3.08-0.63c-0.8-0.83-0.88-1.81-0.37-2.83c0.16-0.32,0.27-0.67,0.31-1.03
					c0.44-3.48,0.36-7.01,1.13-10.46c0.33-1.48-0.13-2.99-0.82-4.36c-3.42-6.77-4.99-14.01-5.81-21.45c-0.26-2.37-1.23-4.5-2.37-6.96
					c-0.55,1.8-0.8,3.22-1.21,4.6c-1.24,4.2-1.49,8.43-1.09,12.81c0.3,3.35,1.06,6.63,1.22,9.98c-0.02,0.29-0.09,0.56-0.19,0.8
					c0.41,1.47,0.65,2.98,0.87,4.48c0.21,0.99,0.43,1.99,0.64,2.98c0.25,1.55,0.5,3.11,0.75,4.66c0.02,0.24,0.04,0.47,0.06,0.71
					c0.45,0.7,0.26,1.47,0.29,2.22c0.12,0.11,0.24,0.25,0.33,0.42c0.98,5.36,0.44,10.74,0.11,16.1c-0.24,3.95-0.9,7.86,0.83,11.66
					c0.34,0.76,0.47,1.63,0.42,2.47c-0.16,2.3,0.62,4.33,1.67,6.35c1.28,2.47,2.26,5.05,2.4,7.87c-0.03,0.43-0.12,0.85-0.23,1.27
					c0.27,1.81,0.22,3.62,0.01,5.43c0.06,0.52,0.1,1.05,0.14,1.57c0.3,2.67-0.41,5.24-0.85,7.83c-0.56,3.34-0.61,6.61-0.09,9.99
					c0.34,2.19,0.78,3.89,2.71,5.27c1.31,0.94,0.86,2.28-0.71,2.88c-0.92,0.35-1.92,0.37-2.88,0.57c-1.26,0.25-2.58-0.34-3.78,0.33
					h-1.11c-1.86-0.75-2.25-1.31-2.09-3.27c0.09-1.21,0.05-2.39-0.16-3.58c-0.19-1.07-0.31-2.2,0.12-3.2c0.92-2.17,0.6-4.36,0.29-6.55
					c-0.28-1.96-0.75-3.89-1.2-5.82c-1.02-4.38-2.57-8.68-2.59-13.21c-0.01-3.52-1-6.76-2.41-9.9c-1.72-3.82-2.82-7.79-3.6-11.87
					c-1-5.25-2.01-10.49-3.24-15.69c-0.58-2.44-1.46-4.84-1.48-7.39c0.01-0.13-0.45-0.51-0.99-0.91c-0.51,0.38-0.93,0.73-0.92,0.86
					c-0.01,2.55-0.9,4.95-1.48,7.39c-1.23,5.2-2.24,10.45-3.24,15.69c-0.78,4.08-1.88,8.05-3.6,11.87c-1.41,3.14-2.4,6.37-2.41,9.9
					c-0.02,4.53-1.57,8.84-2.59,13.21c-0.45,1.93-0.92,3.85-1.2,5.82c-0.31,2.2-0.63,4.38,0.29,6.55c0.43,1.01,0.31,2.14,0.12,3.2
					c-0.21,1.19-0.25,2.38-0.16,3.58c0.15,1.95-0.24,2.51-2.09,3.27h-1.11c-1.2-0.67-2.52-0.08-3.78-0.33
					c-0.96-0.19-1.96-0.21-2.88-0.57c-1.58-0.6-2.03-1.94-0.71-2.88c1.93-1.38,2.37-3.08,2.71-5.27c0.52-3.38,0.48-6.66-0.09-9.99
					c-0.44-2.59-1.15-5.16-0.85-7.83c0.04-0.53,0.07-1.05,0.14-1.57c-0.21-1.81-0.26-3.62,0.01-5.43c-0.11-0.42-0.2-0.85-0.23-1.27
					c0.14-2.81,1.13-5.4,2.4-7.86c1.04-2.02,1.83-4.05,1.67-6.35c-0.06-0.84,0.07-1.71,0.42-2.47c1.72-3.8,1.07-7.72,0.83-11.66
					c-0.33-5.36-0.87-10.74,0.11-16.1c0.09-0.17,0.21-0.3,0.33-0.42c0.03-0.75-0.16-1.52,0.29-2.22c0.02-0.24,0.04-0.47,0.06-0.71
					c0.25-1.55,0.5-3.11,0.75-4.66c0.21-0.99,0.43-1.99,0.64-2.98c0.23-1.51,0.47-3.01,0.87-4.48c-0.1-0.24-0.17-0.5-0.19-0.8
					c0.16-3.35,0.92-6.63,1.22-9.98c0.4-4.38,0.15-8.61-1.09-12.81c-0.41-1.38-0.66-2.8-1.21-4.6c-1.14,2.46-2.1,4.59-2.37,6.96
					c-0.82,7.43-2.39,14.68-5.81,21.45c-0.69,1.37-1.15,2.88-0.82,4.36c0.77,3.46,0.7,6.98,1.13,10.46c0.05,0.36,0.15,0.71,0.31,1.03
					c0.51,1.03,0.43,2.01-0.37,2.83c-0.85,0.88-1.94,0.95-3.08,0.63c-2.72-0.75-3.78-2.85-4.47-5.25v-1.44
					c0.92-2.55,1.18-5.19,1.16-7.89c-0.07-7.81-0.02-15.64,1.9-23.28c1.54-6.1,3.41-12.13,4.53-18.33c0.51-2.85,0.6-5.73,0.89-8.59
					c0.11-1.02,0.31-2.01,0.56-3c0.82-3.3,2.91-5.06,6.33-5.96c3.88-1.03,7.07-3.51,10.19-5.94c0.67-0.52,1.13-1.33,1.01-2.04
					c-0.36-2.21-0.18-4.58-2.2-6.36c-1.27-1.13-1.79-3.08-1.19-4.47c0.63-1.46,0.61-2.87,0.8-4.29c0.49-3.61,3.46-6.44,6.92-7.32
					c0-0.11,0-0.17,0-0.17c0.4-0.05,0.8-0.06,1.2-0.05c0.66-0.08,1.32-0.08,1.99,0C126.91,13.06,126.91,13.15,126.91,13.33z"></path>
						</g>
					</g>
					<path id="e-stat-body_x5F__x5F_head" :opacity="homeOpacity.head / 100" fill="#D20A0A" d="M134.63,24.95
			c-0.63-1.46-0.61-2.87-0.8-4.29c-0.49-3.61-3.46-6.44-6.92-7.32c0-0.18,0-0.28,0-0.28c-0.66-0.08-1.33-0.07-1.99,0
			c-0.4-0.01-0.8,0.01-1.2,0.05c0,0,0,0.06,0,0.17c-3.46,0.87-6.43,3.7-6.92,7.32c-0.19,1.43-0.17,2.84-0.8,4.29
			c-0.6,1.39-0.08,3.35,1.19,4.47c2.01,1.78,1.83,4.14,2.2,6.36c0.12,0.71-0.34,1.52-1.01,2.04c-2.09,1.63-4.22,3.27-6.56,4.49h26.89
			c-2.3-1.22-4.39-2.83-6.45-4.43c-0.67-0.52-1.13-1.33-1.01-2.04c0.36-2.21,0.18-4.58,2.2-6.36
			C134.71,28.29,135.23,26.34,134.63,24.95z"></path>
					
					<path id="e-stat-body_x5F__x5F_body" :opacity="homeOpacity.body / 100" fill="#D20A0A" d="M108.18,43.7
			c-3.41,0.9-5.51,2.66-6.33,5.96c-0.25,0.99-0.45,1.98-0.56,3c-0.3,2.86-0.38,5.74-0.89,8.59c-1.12,6.21-3,12.23-4.53,18.33
			c-1.92,7.64-1.97,15.46-1.9,23.28c0.03,2.69-0.23,5.33-1.16,7.89v1.44c0.69,2.39,1.75,4.49,4.47,5.25c1.14,0.32,2.23,0.25,3.08-0.63
			c0.8-0.83,0.88-1.81,0.37-2.83c-0.16-0.32-0.27-0.67-0.31-1.03c-0.44-3.48-0.36-7.01-1.13-10.46c-0.33-1.48,0.13-2.99,0.82-4.36
			c3.42-6.77,4.99-14.01,5.81-21.45c0.26-2.37,1.23-4.5,2.37-6.96c0.55,1.8,0.8,3.22,1.21,4.6c1.24,4.2,1.49,8.43,1.09,12.81
			c-0.3,3.35-1.06,6.63-1.22,9.98c0.02,0.29,0.09,0.56,0.19,0.8c-0.41,1.47-0.65,2.98-0.87,4.48c-0.21,0.99-0.43,1.99-0.64,2.98
			c-0.25,1.55-0.5,3.11-0.75,4.66c-0.02,0.24-0.04,0.47-0.06,0.71c-0.45,0.7-0.26,1.47-0.29,2.22c-0.12,0.11-0.24,0.25-0.33,0.42
			c-0.39,2.13-0.53,4.25-0.55,6.38h16.84c0.58-2.4,1.44-4.77,1.45-7.29c-0.01-0.12,0.41-0.48,0.92-0.86c0.54,0.4,1.01,0.78,0.99,0.91
			c0.01,2.5,0.86,4.85,1.44,7.24h16.86c-0.02-2.11-0.17-4.22-0.55-6.33c-0.09-0.17-0.21-0.3-0.33-0.42c-0.03-0.75,0.16-1.52-0.29-2.22
			c-0.02-0.24-0.04-0.47-0.06-0.71c-0.25-1.55-0.5-3.11-0.75-4.66c-0.21-0.99-0.43-1.99-0.64-2.98c-0.23-1.51-0.47-3.01-0.87-4.48
			c0.1-0.24,0.17-0.5,0.19-0.8c-0.16-3.35-0.92-6.63-1.22-9.98c-0.4-4.38-0.15-8.61,1.09-12.81c0.41-1.38,0.66-2.8,1.21-4.6
			c1.14,2.46,2.1,4.59,2.37,6.96c0.82,7.43,2.39,14.68,5.81,21.45c0.69,1.37,1.15,2.88,0.82,4.36c-0.77,3.46-0.7,6.98-1.13,10.46
			c-0.05,0.36-0.15,0.71-0.31,1.03c-0.51,1.03-0.43,2.01,0.37,2.83c0.85,0.88,1.94,0.95,3.08,0.63c2.72-0.75,3.78-2.85,4.47-5.25
			v-1.44c-0.92-2.55-1.18-5.19-1.16-7.89c0.07-7.81,0.02-15.64-1.9-23.28c-1.54-6.1-3.41-12.13-4.53-18.33
			c-0.51-2.85-0.6-5.73-0.89-8.59c-0.11-1.02-0.31-2.01-0.56-3c-0.82-3.3-2.91-5.06-6.33-5.96c-1.32-0.35-2.56-0.88-3.74-1.5h-26.89
			C110.66,42.85,109.46,43.36,108.18,43.7z"></path>
					<g id="e-stat-body_x5F__x5F_leg">
						<path :opacity="homeOpacity.legs / 100" fill="#D20A0A" d="M144.56,119.75H127.7c0.01,0.05,0.03,0.1,0.04,0.15
				c1.23,5.2,2.24,10.45,3.24,15.69c0.78,4.08,1.88,8.05,3.6,11.87c1.41,3.14,2.4,6.37,2.41,9.9c0.02,4.53,1.57,8.84,2.59,13.21
				c0.45,1.93,0.92,3.85,1.2,5.82c0.31,2.2,0.63,4.38-0.29,6.55c-0.43,1.01-0.31,2.14-0.12,3.2c0.21,1.19,0.25,2.38,0.16,3.58
				c-0.15,1.95,0.24,2.51,2.09,3.27h1.11c1.2-0.67,2.52-0.08,3.78-0.33c0.96-0.19,1.96-0.21,2.88-0.57c1.58-0.6,2.03-1.94,0.71-2.88
				c-1.93-1.38-2.37-3.08-2.71-5.27c-0.52-3.38-0.48-6.66,0.09-9.99c0.44-2.59,1.15-5.16,0.85-7.83c-0.04-0.53-0.07-1.05-0.14-1.57
				c0.21-1.81,0.26-3.62-0.01-5.43c0.11-0.42,0.2-0.85,0.23-1.27c-0.14-2.81-1.13-5.4-2.4-7.86c-1.04-2.02-1.83-4.05-1.67-6.35
				c0.06-0.84-0.07-1.71-0.42-2.47c-1.72-3.8-1.07-7.72-0.83-11.66C144.32,126.27,144.59,123.01,144.56,119.75z"></path>
						<path :opacity="homeOpacity.legs / 100" fill="#D20A0A" d="M100.23,192.05c0.92,0.35,1.92,0.37,2.88,0.57
				c1.26,0.25,2.58-0.34,3.78,0.33H108c1.86-0.75,2.25-1.31,2.09-3.27c-0.09-1.21-0.05-2.39,0.16-3.58c0.19-1.07,0.31-2.2-0.12-3.2
				c-0.92-2.17-0.6-4.36-0.29-6.55c0.28-1.96,0.75-3.89,1.2-5.82c1.02-4.38,2.57-8.68,2.59-13.21c0.01-3.52,1-6.76,2.41-9.9
				c1.72-3.82,2.82-7.79,3.6-11.87c1-5.25,2.01-10.49,3.24-15.69c0.01-0.03,0.02-0.07,0.02-0.1h-16.84c-0.03,3.24,0.24,6.48,0.44,9.71
				c0.24,3.95,0.9,7.86-0.83,11.66c-0.34,0.76-0.47,1.63-0.42,2.47c0.16,2.3-0.62,4.33-1.67,6.35c-1.28,2.47-2.26,5.05-2.4,7.87
				c0.03,0.43,0.12,0.85,0.23,1.27c-0.27,1.81-0.22,3.62-0.01,5.43c-0.06,0.52-0.1,1.05-0.14,1.57c-0.3,2.67,0.41,5.24,0.85,7.83
				c0.56,3.34,0.61,6.61,0.09,9.99c-0.34,2.19-0.78,3.89-2.71,5.27C98.2,190.12,98.65,191.45,100.23,192.05z"></path>
					</g>
				</svg>
			</div>
			<div class="live-statistic_col-3">
				<svg class="c-stat-body__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="268px" height="208px" viewBox="0 0 268 208">
					<g id="e-stat-body_x5F__x5F_bg">
						<line id="line_2_" fill="none" stroke="#EAEAEA" stroke-linecap="square" stroke-miterlimit="10" x1="0.5" y1="39.5" x2="266.14" y2="39.5"></line>
						
						<line id="line_1_" fill="none" stroke="#EAEAEA" stroke-linecap="square" stroke-miterlimit="10" x1="0.5" y1="98.5" x2="266.14" y2="98.5"></line>
						
						<line id="line" fill="none" stroke="#EAEAEA" stroke-linecap="square" stroke-miterlimit="10" x1="0.5" y1="152.5" x2="266.14" y2="152.5"></line>
					</g>
					
					<g id="e-stat-body_x5F__x5F_head-txt">
						<text id="e-stat-body_x5F__x5F_head_percent" transform="matrix(1 0 0 1 230.8119 32)" fill="#16559F" font-size="14px">{{ awayOpacity.head }}%</text>
						<text id="e-stat-body_x5F__x5F_head_value" transform="matrix(1 0 0 1 197.8119 32)" fill="#16559F" font-size="14px">{{ away.strikes_power.head }}</text>
						<text transform="matrix(1 0 0 1 3.8119 33)" fill="#1C1C1C" font-weight="600" font-size="16px">Голова</text>
					</g>
					<g id="e-stat-body_x5F__x5F_body-txt">
						<text id="e-stat-body_x5F__x5F_body_percent" transform="matrix(1 0 0 1 226.8119 90)" fill="#16559F" font-size="14px">{{ awayOpacity.body }}%</text>
						<text id="e-stat-body_x5F__x5F_body_value" transform="matrix(1 0 0 1 197.8119 90)" fill="#16559F" font-size="14px">{{ away.strikes_power.body }}</text>
						<text transform="matrix(1 0 0 1 4.8119 91)" fill="#1C1C1C" font-weight="600" font-size="16px">Корпус</text>
					</g>
					<g id="e-stat-body_x5F__x5F_leg-txt">
						<text id="e-stat-body_x5F__x5F_leg_percent" transform="matrix(1 0 0 1 226.8119 145)" fill="#16559F" font-size="14px">{{ awayOpacity.legs }}%</text>
						<text id="e-stat-body_x5F__x5F_leg_value" transform="matrix(1 0 0 1 197.8119 145)" fill="#16559F" font-size="14px">{{ away.strikes_power.legs }}</text>
						<text transform="matrix(1 0 0 1 8.8119 146)" fill="#1C1C1C" font-weight="600" font-size="16px">Ноги</text>
					</g>
					
					
					<g id="e-stat-body_x5F__x5F_outline">
						<g>
							<path fill="#FAFAFA" stroke="#FAFAFA" stroke-width="6" stroke-miterlimit="10" d="M126.91,13.33
					c3.46,0.87,6.43,3.7,6.92,7.32c0.19,1.43,0.17,2.84,0.8,4.29c0.6,1.39,0.08,3.35-1.19,4.47c-2.01,1.78-1.83,4.14-2.2,6.36
					c-0.12,0.71,0.34,1.52,1.01,2.04c3.13,2.43,6.31,4.91,10.19,5.94c3.41,0.9,5.51,2.66,6.33,5.96c0.25,0.99,0.45,1.98,0.56,3
					c0.3,2.86,0.38,5.74,0.89,8.59c1.12,6.21,3,12.23,4.53,18.33c1.92,7.64,1.97,15.46,1.9,23.28c-0.03,2.69,0.23,5.33,1.16,7.89v1.44
					c-0.69,2.39-1.75,4.49-4.47,5.25c-1.14,0.32-2.23,0.25-3.08-0.63c-0.8-0.83-0.88-1.81-0.37-2.83c0.16-0.32,0.27-0.67,0.31-1.03
					c0.44-3.48,0.36-7.01,1.13-10.46c0.33-1.48-0.13-2.99-0.82-4.36c-3.42-6.77-4.99-14.01-5.81-21.45c-0.26-2.37-1.23-4.5-2.37-6.96
					c-0.55,1.8-0.8,3.22-1.21,4.6c-1.24,4.2-1.49,8.43-1.09,12.81c0.3,3.35,1.06,6.63,1.22,9.98c-0.02,0.29-0.09,0.56-0.19,0.8
					c0.41,1.47,0.65,2.98,0.87,4.48c0.21,0.99,0.43,1.99,0.64,2.98c0.25,1.55,0.5,3.11,0.75,4.66c0.02,0.24,0.04,0.47,0.06,0.71
					c0.45,0.7,0.26,1.47,0.29,2.22c0.12,0.11,0.24,0.25,0.33,0.42c0.98,5.36,0.44,10.74,0.11,16.1c-0.24,3.95-0.9,7.86,0.83,11.66
					c0.34,0.76,0.47,1.63,0.42,2.47c-0.16,2.3,0.62,4.33,1.67,6.35c1.28,2.47,2.26,5.05,2.4,7.87c-0.03,0.43-0.12,0.85-0.23,1.27
					c0.27,1.81,0.22,3.62,0.01,5.43c0.06,0.52,0.1,1.05,0.14,1.57c0.3,2.67-0.41,5.24-0.85,7.83c-0.56,3.34-0.61,6.61-0.09,9.99
					c0.34,2.19,0.78,3.89,2.71,5.27c1.31,0.94,0.86,2.28-0.71,2.88c-0.92,0.35-1.92,0.37-2.88,0.57c-1.26,0.25-2.58-0.34-3.78,0.33
					h-1.11c-1.86-0.75-2.25-1.31-2.09-3.27c0.09-1.21,0.05-2.39-0.16-3.58c-0.19-1.07-0.31-2.2,0.12-3.2c0.92-2.17,0.6-4.36,0.29-6.55
					c-0.28-1.96-0.75-3.89-1.2-5.82c-1.02-4.38-2.57-8.68-2.59-13.21c-0.01-3.52-1-6.76-2.41-9.9c-1.72-3.82-2.82-7.79-3.6-11.87
					c-1-5.25-2.01-10.49-3.24-15.69c-0.58-2.44-1.46-4.84-1.48-7.39c0.01-0.13-0.45-0.51-0.99-0.91c-0.51,0.38-0.93,0.73-0.92,0.86
					c-0.01,2.55-0.9,4.95-1.48,7.39c-1.23,5.2-2.24,10.45-3.24,15.69c-0.78,4.08-1.88,8.05-3.6,11.87c-1.41,3.14-2.4,6.37-2.41,9.9
					c-0.02,4.53-1.57,8.84-2.59,13.21c-0.45,1.93-0.92,3.85-1.2,5.82c-0.31,2.2-0.63,4.38,0.29,6.55c0.43,1.01,0.31,2.14,0.12,3.2
					c-0.21,1.19-0.25,2.38-0.16,3.58c0.15,1.95-0.24,2.51-2.09,3.27h-1.11c-1.2-0.67-2.52-0.08-3.78-0.33
					c-0.96-0.19-1.96-0.21-2.88-0.57c-1.58-0.6-2.03-1.94-0.71-2.88c1.93-1.38,2.37-3.08,2.71-5.27c0.52-3.38,0.48-6.66-0.09-9.99
					c-0.44-2.59-1.15-5.16-0.85-7.83c0.04-0.53,0.07-1.05,0.14-1.57c-0.21-1.81-0.26-3.62,0.01-5.43c-0.11-0.42-0.2-0.85-0.23-1.27
					c0.14-2.81,1.13-5.4,2.4-7.86c1.04-2.02,1.83-4.05,1.67-6.35c-0.06-0.84,0.07-1.71,0.42-2.47c1.72-3.8,1.07-7.72,0.83-11.66
					c-0.33-5.36-0.87-10.74,0.11-16.1c0.09-0.17,0.21-0.3,0.33-0.42c0.03-0.75-0.16-1.52,0.29-2.22c0.02-0.24,0.04-0.47,0.06-0.71
					c0.25-1.55,0.5-3.11,0.75-4.66c0.21-0.99,0.43-1.99,0.64-2.98c0.23-1.51,0.47-3.01,0.87-4.48c-0.1-0.24-0.17-0.5-0.19-0.8
					c0.16-3.35,0.92-6.63,1.22-9.98c0.4-4.38,0.15-8.61-1.09-12.81c-0.41-1.38-0.66-2.8-1.21-4.6c-1.14,2.46-2.1,4.59-2.37,6.96
					c-0.82,7.43-2.39,14.68-5.81,21.45c-0.69,1.37-1.15,2.88-0.82,4.36c0.77,3.46,0.7,6.98,1.13,10.46c0.05,0.36,0.15,0.71,0.31,1.03
					c0.51,1.03,0.43,2.01-0.37,2.83c-0.85,0.88-1.94,0.95-3.08,0.63c-2.72-0.75-3.78-2.85-4.47-5.25v-1.44
					c0.92-2.55,1.18-5.19,1.16-7.89c-0.07-7.81-0.02-15.64,1.9-23.28c1.54-6.1,3.41-12.13,4.53-18.33c0.51-2.85,0.6-5.73,0.89-8.59
					c0.11-1.02,0.31-2.01,0.56-3c0.82-3.3,2.91-5.06,6.33-5.96c3.88-1.03,7.07-3.51,10.19-5.94c0.67-0.52,1.13-1.33,1.01-2.04
					c-0.36-2.21-0.18-4.58-2.2-6.36c-1.27-1.13-1.79-3.08-1.19-4.47c0.63-1.46,0.61-2.87,0.8-4.29c0.49-3.61,3.46-6.44,6.92-7.32
					c0-0.11,0-0.17,0-0.17c0.4-0.05,0.8-0.06,1.2-0.05c0.66-0.08,1.32-0.08,1.99,0C126.91,13.06,126.91,13.15,126.91,13.33z"></path>
						</g>
					</g>
					<path id="e-stat-body_x5F__x5F_head" :opacity="awayOpacity.head / 100" fill="#16559F" d="M134.63,24.95
			c-0.63-1.46-0.61-2.87-0.8-4.29c-0.49-3.61-3.46-6.44-6.92-7.32c0-0.18,0-0.28,0-0.28c-0.66-0.08-1.33-0.07-1.99,0
			c-0.4-0.01-0.8,0.01-1.2,0.05c0,0,0,0.06,0,0.17c-3.46,0.87-6.43,3.7-6.92,7.32c-0.19,1.43-0.17,2.84-0.8,4.29
			c-0.6,1.39-0.08,3.35,1.19,4.47c2.01,1.78,1.83,4.14,2.2,6.36c0.12,0.71-0.34,1.52-1.01,2.04c-2.09,1.63-4.22,3.27-6.56,4.49h26.89
			c-2.3-1.22-4.39-2.83-6.45-4.43c-0.67-0.52-1.13-1.33-1.01-2.04c0.36-2.21,0.18-4.58,2.2-6.36
			C134.71,28.29,135.23,26.34,134.63,24.95z"></path>
					
					<path id="e-stat-body_x5F__x5F_body" :opacity="awayOpacity.body / 100" fill="#16559F" d="M108.18,43.7
			c-3.41,0.9-5.51,2.66-6.33,5.96c-0.25,0.99-0.45,1.98-0.56,3c-0.3,2.86-0.38,5.74-0.89,8.59c-1.12,6.21-3,12.23-4.53,18.33
			c-1.92,7.64-1.97,15.46-1.9,23.28c0.03,2.69-0.23,5.33-1.16,7.89v1.44c0.69,2.39,1.75,4.49,4.47,5.25c1.14,0.32,2.23,0.25,3.08-0.63
			c0.8-0.83,0.88-1.81,0.37-2.83c-0.16-0.32-0.27-0.67-0.31-1.03c-0.44-3.48-0.36-7.01-1.13-10.46c-0.33-1.48,0.13-2.99,0.82-4.36
			c3.42-6.77,4.99-14.01,5.81-21.45c0.26-2.37,1.23-4.5,2.37-6.96c0.55,1.8,0.8,3.22,1.21,4.6c1.24,4.2,1.49,8.43,1.09,12.81
			c-0.3,3.35-1.06,6.63-1.22,9.98c0.02,0.29,0.09,0.56,0.19,0.8c-0.41,1.47-0.65,2.98-0.87,4.48c-0.21,0.99-0.43,1.99-0.64,2.98
			c-0.25,1.55-0.5,3.11-0.75,4.66c-0.02,0.24-0.04,0.47-0.06,0.71c-0.45,0.7-0.26,1.47-0.29,2.22c-0.12,0.11-0.24,0.25-0.33,0.42
			c-0.39,2.13-0.53,4.25-0.55,6.38h16.84c0.58-2.4,1.44-4.77,1.45-7.29c-0.01-0.12,0.41-0.48,0.92-0.86c0.54,0.4,1.01,0.78,0.99,0.91
			c0.01,2.5,0.86,4.85,1.44,7.24h16.86c-0.02-2.11-0.17-4.22-0.55-6.33c-0.09-0.17-0.21-0.3-0.33-0.42c-0.03-0.75,0.16-1.52-0.29-2.22
			c-0.02-0.24-0.04-0.47-0.06-0.71c-0.25-1.55-0.5-3.11-0.75-4.66c-0.21-0.99-0.43-1.99-0.64-2.98c-0.23-1.51-0.47-3.01-0.87-4.48
			c0.1-0.24,0.17-0.5,0.19-0.8c-0.16-3.35-0.92-6.63-1.22-9.98c-0.4-4.38-0.15-8.61,1.09-12.81c0.41-1.38,0.66-2.8,1.21-4.6
			c1.14,2.46,2.1,4.59,2.37,6.96c0.82,7.43,2.39,14.68,5.81,21.45c0.69,1.37,1.15,2.88,0.82,4.36c-0.77,3.46-0.7,6.98-1.13,10.46
			c-0.05,0.36-0.15,0.71-0.31,1.03c-0.51,1.03-0.43,2.01,0.37,2.83c0.85,0.88,1.94,0.95,3.08,0.63c2.72-0.75,3.78-2.85,4.47-5.25
			v-1.44c-0.92-2.55-1.18-5.19-1.16-7.89c0.07-7.81,0.02-15.64-1.9-23.28c-1.54-6.1-3.41-12.13-4.53-18.33
			c-0.51-2.85-0.6-5.73-0.89-8.59c-0.11-1.02-0.31-2.01-0.56-3c-0.82-3.3-2.91-5.06-6.33-5.96c-1.32-0.35-2.56-0.88-3.74-1.5h-26.89
			C110.66,42.85,109.46,43.36,108.18,43.7z"></path>
					<g id="e-stat-body_x5F__x5F_leg">
						<path :opacity="awayOpacity.legs / 100" fill="#16559F" d="M144.56,119.75H127.7c0.01,0.05,0.03,0.1,0.04,0.15
				c1.23,5.2,2.24,10.45,3.24,15.69c0.78,4.08,1.88,8.05,3.6,11.87c1.41,3.14,2.4,6.37,2.41,9.9c0.02,4.53,1.57,8.84,2.59,13.21
				c0.45,1.93,0.92,3.85,1.2,5.82c0.31,2.2,0.63,4.38-0.29,6.55c-0.43,1.01-0.31,2.14-0.12,3.2c0.21,1.19,0.25,2.38,0.16,3.58
				c-0.15,1.95,0.24,2.51,2.09,3.27h1.11c1.2-0.67,2.52-0.08,3.78-0.33c0.96-0.19,1.96-0.21,2.88-0.57c1.58-0.6,2.03-1.94,0.71-2.88
				c-1.93-1.38-2.37-3.08-2.71-5.27c-0.52-3.38-0.48-6.66,0.09-9.99c0.44-2.59,1.15-5.16,0.85-7.83c-0.04-0.53-0.07-1.05-0.14-1.57
				c0.21-1.81,0.26-3.62-0.01-5.43c0.11-0.42,0.2-0.85,0.23-1.27c-0.14-2.81-1.13-5.4-2.4-7.86c-1.04-2.02-1.83-4.05-1.67-6.35
				c0.06-0.84-0.07-1.71-0.42-2.47c-1.72-3.8-1.07-7.72-0.83-11.66C144.32,126.27,144.59,123.01,144.56,119.75z"></path>
						<path :opacity="awayOpacity.legs / 100" fill="#16559F" d="M100.23,192.05c0.92,0.35,1.92,0.37,2.88,0.57
				c1.26,0.25,2.58-0.34,3.78,0.33H108c1.86-0.75,2.25-1.31,2.09-3.27c-0.09-1.21-0.05-2.39,0.16-3.58c0.19-1.07,0.31-2.2-0.12-3.2
				c-0.92-2.17-0.6-4.36-0.29-6.55c0.28-1.96,0.75-3.89,1.2-5.82c1.02-4.38,2.57-8.68,2.59-13.21c0.01-3.52,1-6.76,2.41-9.9
				c1.72-3.82,2.82-7.79,3.6-11.87c1-5.25,2.01-10.49,3.24-15.69c0.01-0.03,0.02-0.07,0.02-0.1h-16.84c-0.03,3.24,0.24,6.48,0.44,9.71
				c0.24,3.95,0.9,7.86-0.83,11.66c-0.34,0.76-0.47,1.63-0.42,2.47c0.16,2.3-0.62,4.33-1.67,6.35c-1.28,2.47-2.26,5.05-2.4,7.87
				c0.03,0.43,0.12,0.85,0.23,1.27c-0.27,1.81-0.22,3.62-0.01,5.43c-0.06,0.52-0.1,1.05-0.14,1.57c-0.3,2.67,0.41,5.24,0.85,7.83
				c0.56,3.34,0.61,6.61,0.09,9.99c-0.34,2.19-0.78,3.89-2.71,5.27C98.2,190.12,98.65,191.45,100.23,192.05z"></path>
					</g>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		data() {
			return {
				
			}
		},
		
		computed: {
			home() {
				return this.data.localteam;
			},
			away() {
				return this.data.awayteam;
			},
			
			homeOpacity() {
				let total = parseInt(this.home.strikes_power.head) + parseInt(this.home.strikes_power.body) + parseInt(this.home.strikes_power.legs);
				
				return {
					head: Math.round(this.home.strikes_power.head / total * 100),
					body: Math.round(this.home.strikes_power.body / total * 100),
					legs: Math.round(this.home.strikes_power.legs / total * 100),
				}
			},
			awayOpacity() {
				let total = parseInt(this.away.strikes_power.head) + parseInt(this.away.strikes_power.body) + parseInt(this.away.strikes_power.legs);
				
				return {
					head: Math.round(this.away.strikes_power.head / total * 100),
					body: Math.round(this.away.strikes_power.body / total * 100),
					legs: Math.round(this.away.strikes_power.legs / total * 100),
				}
			},
		},

		methods: {
			getPercentage(home, away) {
				var total = home + away;
				
				return {
					home: parseInt(home / total * 100) || 0,
					away: parseInt(away / total * 100) || 0
				}
			},
			
			getWidth(home, away) {
				return this.getPercentage(parseInt(home), parseInt(away));
			},
		},
	}
</script>