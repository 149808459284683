<template>
	<div>
		<match-page-soccer v-if="sport === 'soccer'"></match-page-soccer>
		<match-page-hockey v-if="sport === 'hockey'"></match-page-hockey>
		<match-page-basketball v-if="sport === 'bsktbl'"></match-page-basketball>
	</div>
</template>

<script>
	import MatchPageSoccer from "../components/Sports/Soccer/Pages/MatchPage";
	import MatchPageHockey from "../components/Sports/Hockey/Pages/MatchPage";
	import MatchPageBasketball from "../components/Sports/Basketball/Pages/MatchPage";
	
	export default {
		components: {
			MatchPageSoccer,
			MatchPageHockey,
			MatchPageBasketball,
		},
		data() {
			return {
				sport: this.$route.params.sport,
			}
		}
	}
</script>

<style scoped>

</style>