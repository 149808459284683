<template>
	<div>
		<banner :type="sport"></banner>
		
		<home-matches v-if="isCurrentSport('soccer')" :matches="matches" title="Футбол" slug="soccer"></home-matches>
		
		<tennis-matches v-if="isCurrentSport('tennis')" :matches="matches" title="Теннис" slug="tennis"></tennis-matches>
		
		<hockey-matches v-if="isCurrentSport('hockey')" :matches="matches" title="Хоккей" slug="hockey"></hockey-matches>
		
		<basketball-matches v-if="isCurrentSport('bsktbl')" :matches="matches" title="Баскетбол" slug="bsktbl"></basketball-matches>
		
		<home-matches v-if="isCurrentSport('volleyball')" :matches="matches" title="Волейбол" slug="volleyball"></home-matches>
		
		<home-matches v-if="isCurrentSport('box')" :matches="matches" title="Бокс" slug="box"></home-matches>
		
		<home-matches v-if="isCurrentSport('mma')" :matches="matches" title="ММА" slug="mma"></home-matches>
		
		<home-matches v-if="isCurrentSport('biathlon')" :matches="matches" title="Биатлон" slug="biathlon"></home-matches>
	
		<news :sport="sportBase"></news>
		
		<videos :sport="sportBase"></videos>
	
		<games :sport="sportBase"></games>
	</div>
</template>

<script>
	import moment from 'moment';
	
	import HomeMatches from "../components/Match/HomeMatches";
	import TennisMatches from "../components/Match/TennisMatches";
	import BasketballMatches from "../components/Match/BasketballMatches";
	import HockeyMatches from "../components/Match/HockeyMatches";
	import News from "../components/News/News";
	import Videos from "../components/Videos/Videos";
	import Games from "../components/Games/Games";
	import Banner from "../components/Layout/Banner";
	
	export default {
		components: {
			HomeMatches,
			TennisMatches,
			BasketballMatches,
			HockeyMatches,
			News,
			Videos,
			Games,
			Banner
		},
		
		data() {
			return {
				sport: null,
				matches: null,
				sportBase: null,
			}
		},
		
		created() {
			this.sport = this.$route.params.sport ? this.$route.params.sport : '';
			this.sportBase = this.sport;
			
			if (this.sport === 'bsktbl') {
				this.sportBase = 'basketball';
			}
			
			this.initEvents();
		},
		
		methods: {
			initEvents() {
				this.axios.get('/goalserve/' + this.sport + '/league/events')
					.then(({data}) => {
						this.matches = data.res;
					});
			},
			
			isCurrentSport(sport) {
				return !(this.sport && this.sport !== sport);
			}
		},
	}
</script>