<template>
	<div>
		<div class="subscribe-form" v-if="!authError.error">
			<div class="subscribe-form_ico">
				<img src="/img/ico-phone.svg" width="55" alt="">
			</div>
			<div class="subscribe-form_text">Чтобы продолжить использовать сервис без ограничений, необходимо оформить доступ</div>
			
			<a href="" @click.prevent="subscribe" class="subscribe-form_button">Получить доступ</a>
			
			<div v-if="loading" class="subscribe-form_spinner">
				<img src="/img/spinner.gif" width="44" alt="">
			</div>
			
			<div class="subscribe-form_text-2">
				Чтобы получить доступ, наберите со своего мобильного <strong>-</strong> <br><br>
				<span style="color: red"> Стоимость - 1,20 TJS/день</span>
			</div>
			
			<div v-if="error" class="subscribe-form_error">
				Ошибка подписки. Попробуйте еще раз
			</div>
		</div>
		<div class="error-page" v-else>
			Сервис временно недоступен
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				error: null,
				loading: false,
				login: null,
				authError: window.error
			}
		},
		
		methods: {
			subscribe() {
				if (this.loading) return;
				
				this.loading = true;
				this.error = false;
				
				this.axios.get('/a1/subscribe').then(({data}) => {
					if (data.status === 'subscribed') {
						location.href = '/';
					} else {
						this.error = true;
						this.loading = false;
					}
				});
			},
		},
	}
</script>

<style scoped>

</style>
