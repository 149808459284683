<template>
	<div>
		<div class="tab_header">
			<a @click.prevent="change(title)" :href="title" :class="{'router-link-active' : sportValue === title}" v-for="(item, title) in items" :key="title">
				{{ item }}
			</a>
		</div>
	</div>
	
</template>

<script>
	export default {
		props: ['sport'],
		
		data() {
			return {
				items: {
					'': 'Все',
					soccer: 'Футбол',
					tennis: 'Теннис',
					hockey: 'Хоккей',
					basketball: 'Баскетбол',
					volleyball: 'Волейбол',
					box: 'Бокс',
					mma: 'ММА',
					biathlon: 'Биатлон',
				},
				sportValue: this.sport || ''
			}
		},
		methods: {
			change(value) {
				this.$emit('update', value);
			}
		},
		
		created() {
			
		}
	}
</script>

<style scoped>

</style>