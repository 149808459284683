<template>
	<div>
		<div class="timeline_row" :class="{__hidden: incident.minute === -1}" v-for="(incident, index) in incidents" :key="index">
			<div class="timeline_left">
				<div class="timeline_name" v-if="incident.team === 'localteam'">
					<timeline-data :data="incident"></timeline-data>
				</div>
				<div class="timeline_time" v-if="incident.team === 'visitorteam'">{{ incident.minute }}'</div>
			</div>
			<div class="timeline_center">
				<div class="timeline_cart __yellow" v-if="incident.type === 'yellowcards' || incident.type === 'yellowcard'"></div>
				<div class="timeline_cart __red" v-if="incident.type === 'redcards' || incident.type === 'redcard'"></div>
				<div class="timeline_cart __yellow __yellow-2" v-if="incident.type === 'yellowred'"></div>
				<div class="timeline_ball" v-else-if="incident.type === 'goals' || incident.type === 'goal'"></div>
				<div class="timeline_arrow __subst" v-else-if="incident.type === 'subst'"></div>
				<div class="timeline_warning" v-else-if="incident.type === 'pen miss' || incident.type === 'pen. miss'"></div>
				<div class="timeline_var" v-else-if="incident.type === 'var'"></div>
			</div>
			<div class="timeline_right">
				<div class="timeline_name" v-if="incident.team === 'visitorteam'">
					<timeline-data :data="incident"></timeline-data>
				</div>
				<div class="timeline_time" v-if="incident.team === 'localteam'">{{ incident.minute }}'</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TimelineData from "./TimelineData";
	
	export default {
		components: {
			TimelineData
		},
		
		props: ['incidents'],
		
		methods: {
			showTime(incident) {
				return true
			}
		}
	}
</script>