<template>
	<!-- eslint-disable -->
	
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="group in matches">
				<div>
					<div class="match_item-header active">
						<span v-if="group.league_name" v-text="group.league_name"></span>
						<span v-else>{{ getTitle(group.group_prefix, group.group_suffix) }}</span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<router-link v-for="match in group.events"
										 class="match-data_item" 
										 :to="{ name: 'event', params: {id: match.id, league: match.league_id, sport: 'bsktbl'} }">
								<link-bsktbl :match="match" :show-date="showDate"></link-bsktbl>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LinkBsktbl from "./Link";
	
	export default {
		components: {
			LinkBsktbl
		},
		
		props: ['data', 'showDate'],
		
		computed: {
			matches() {
				return this.data;
			},
		},
		
		methods: {
			getTitle(prefix, suffix) {
				let data = {
					'Tour': 'Тур',
					'Week': 'Тур',
					'Group Stage': 'Групповая стадия',
					'Play-offs': 'Плей-офф',
					'16th Finals': '1/16 финала',
					'8th Finals': '1/8 финала',
					'Quarter-finals': 'Четвертьфиналы',
					'Semi-finals': 'Полуфиналы',
					'Final': 'Финал',
					'3rd Qualifying Round': 'Квалификация',
					'2nd Qualifying Round': 'Квалификация',
					'1st Qualifying Round': 'Квалификация',
					'Preliminary Round': 'Предварительный раунд',
					'Promotion Play-offs - Final': 'Плей-офф, финал',
					'Матчи': 'Матчи',
				};
				
				data[prefix] = data[prefix] ? data[prefix]: '';
				
				return data[prefix] + ' ' + suffix;
			}
		}
	}
</script>

<style scoped>

</style>