<template>
	<div class="block __dark" v-if="videos">
		<div class="wrapper">
			<div class="header-block">
				<h2 class="header-block_title">Видео</h2>
				
				<router-link to="/videos" class="header-block_link">Все видео</router-link>
			</div>
			<div class="tab">
				<div class="tab_content">
					<div class="tab_block active">
						<slick ref="slick" :options="options" class="video-slider">
							<div v-for="(item) in videos" :key="item.id">
								<div class="video-block_item">
									<div @click="openVideo($event)" class="video-block_image" style="height: 180px;">
										<img :src="$hostname + item.image_ref" alt="">
									</div>
									
									<video :poster="$hostname + item.image_ref" controls="controls" style="width: 100%; height: 180px; display: none" :src="item.video_file"></video>
									
									<router-link :to="{name: 'video', params: {id: item.id}}" class="video-block_title __main">
										{{ item.title }}
									</router-link>
								</div>
							</div>
						</slick>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Slick from 'vue-slick'

	export default {
		props: ['sport'],
		
		components: {
			Slick
		},

		data() {
			return {
				videos: null,
				options: {
					slidesToShow: 4,
					arrows: false,
					dots: true,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 5000,
					adaptiveHeight: true,

					responsive: [
						{
							breakpoint: 1000,
							settings: 'unslick'
							/*settings: {
								slidesToShow: 3,
								arrows: false,
								infinite: true,
								autoplay: true,
								autoplaySpeed: 5000,
							}*/
						},
						/*{
							breakpoint: 767,
							settings: {
								slidesToShow: 1,
								variableWidth: true,
								dots: false,
								arrows: false,
								infinite: true,
								autoplay: true,
								autoplaySpeed: 5000,
							}
						},
						{
							breakpoint: 481,
							settings: {
								slidesToShow: 1,
								variableWidth: true,
								dots: false,
								arrows: false,
								infinite: true,
								autoplay: true,
								autoplaySpeed: 5000,
							}
						},*/
					]
				}
			}
		},

		created() {
			this.axios.get('/api/videos?limit=5&sport=' + this.sport)
				.then(({data}) => {
					this.videos = data.data;
				});
		},
		methods: {
			openVideo(e) {
				$(e.target).closest('.video-block_image').hide();
				$(e.target).closest('.video-block_item').find('video').show();
				$(e.target).closest('.video-block_item').find('video').get(0).play();
				
				this.$refs.slick.pause();
			}
		}
	}
</script>

<style scoped>

</style>
