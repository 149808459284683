<template>
	<!-- eslint-disable -->
	
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="group in matches">
				<div>
					<div class="match_item-header active">
						<span v-text="group.league_name"></span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<router-link v-for="match in group.events"
										 class="match-data_item" 
										 :to="{ name: 'event-tennis', params: {id: match.id,  sport: 'tennis'} }">
								<link-tennis :match="match"></link-tennis>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LinkTennis from "./Link";
	
	export default {
		components: {
			LinkTennis
		},
		
		props: ['data', 'showDate'],
		
		computed: {
			matches() {
				return this.data;
			},
		},
		
		methods: {
			
		}
	}
</script>

<style scoped>

</style>