<template>
	<div>
		<div class="match">
			<div class="wrapper">
				<div class="match_row">
					<leagues v-if="leagues" :sport="sport" :data="leagues"></leagues>
					
					<div class="match_right">
						<match-header></match-header>
						
						<tab :headings="{results: 'Результаты', schedule: 'Расписание', standings: 'Таблица', scorers: 'Бомбардиры'}" active-key="results">
							<template v-slot:results>
								<links v-if="results" :data="results"></links>
								<div v-else-if="resultsLoading" class="info-text"></div>
								<div v-else-if="!resultsLoading" class="info">Нет данных</div>
							</template>
							<template v-slot:schedule>
								<links :show-date="true" v-if="schedule" :data="schedule"></links>
								<div v-else class="info">Нет данных</div>
							</template>
							<template v-slot:standings>
								<standings v-if="standings" :data="standings"></standings>
								<div v-else class="info">Нет данных</div>
							</template>
							<template v-slot:scorers>
								<scorers v-if="scorers" :data="scorers"></scorers>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MatchHeader from "../../Common/MatchHeader";
	import Leagues from "../../Common/Leagues";
	import Tab from "../../../Tab/Tab";
	import Standings from "../Components/Events/Standings";
	import Scorers from "../Components/Events/Scorers";
	import Links from "../Components/Events/Links";
	
	export default {
		components: {
			MatchHeader,
			Leagues,
			Tab,
			Standings,
			Scorers,
			Links
		},
		
		data() {
			return {
				id: this.$route.params.id,
				leagues: null,
				sport: this.$route.params.sport,
				standings: null,
				scorers: null,
				results: null,
				resultsLoading: true,
				schedule: null
			}
		},
		
		created() {
			this.initLeagues();
			
			this.initStandings();
			
			this.initScorers();
			
			this.initResults();
			
			this.initSchedule();
		},
		
		
		methods: {
			initLeagues() {
				this.axios.get('/goalserve/' + this.sport + '/league')
					.then(({data}) => {
						this.leagues = data;
					});
			},
			initStandings() {
				this.axios.get('/goalserve/' + this.sport + '/league/standings?league_id=' + this.id)
					.then(({data}) => {
						this.standings = data.res ? data.res : null;
					});
			},
			initScorers() {
				this.axios.get('/goalserve/' + this.sport + '/league/topscorers?league_id=' + this.id)
					.then(({data}) => {
						this.scorers = data.res;
					});
			},
			initResults() {
				this.axios.get('/goalserve/' + this.sport + '/league/soccerfixtures?status=finished&league_id=' + this.id)
					.then(({data}) => {
						this.results = data.res.reverse();
						this.resultsLoading = false;
					})
					.catch(() => {
						this.resultsLoading = false;
					});
			},
			initSchedule() {
				this.axios.get('/goalserve/' + this.sport + '/league/soccerfixtures?status=not_finished&league_id=' + this.id)
					.then(({data}) => {
						this.schedule = data.res;
					});
			}
		}
	}
</script>