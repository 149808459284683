<template>
	<div>
		<div class="timeline_row" v-for="(incident, index) in incidents" :key="index">
			<div class="timeline_left">
				<div class="timeline_name" v-if="incident.team === 'hometeam'">
					<div>
						{{ incident.player }}
						<div v-if="incident.assist">({{getAssistName(incident.assist)}})</div>
						<div v-if="incident.reason">({{incident.reason}})</div>
						<div v-else-if="incident.goal_type === 'pp'">(Большинство)</div>
					</div>
				</div>
				<div class="timeline_time" v-if="incident.team === 'awayteam'">{{ incident.min }}</div>
			</div>
			<div class="timeline_center">
				<div class="timeline_cart __yellow __card" v-if="incident.type === 'penalty'"></div>
				<div class="timeline_ball __hockey" v-else-if="incident.type === 'goal'"></div>
			</div>
			<div class="timeline_right">
				<div class="timeline_name" v-if="incident.team === 'awayteam'">
					<div>
						{{ incident.player }}
						<div v-if="incident.assist">({{getAssistName(incident.assist)}})</div>
						<div v-if="incident.reason">({{incident.reason}})</div>
						<div v-if="incident.goal_type === 'reg'">(Обычный)</div>
						<div v-else-if="incident.goal_type === 'pp'">(Большинство)</div>
					</div>
				</div>
				<div class="timeline_time" v-if="incident.team === 'hometeam'">{{ incident.min }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['incidents'],
		
		methods: {
			getAssistName(name) {
				return name.replace(',', ' + ')
			}
		}
	}
</script>

<style scoped>

</style>