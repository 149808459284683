<template>
	<div>
		<player-page-sport v-if="sport === 'soccer'"></player-page-sport>
		<player-page-tennis v-if="sport === 'tennis'"></player-page-tennis>
	</div>
</template>

<script>
	import PlayerPageSport from "../components/Sports/Soccer/Pages/PlayerPage";
	import PlayerPageTennis from "../components/Sports/Tennis/Pages/PlayerPage";
	
	export default {
		components: {
			PlayerPageSport,
			PlayerPageTennis
		},
		
		data() {
			return {
				sport: this.$route.params.sport
			}
		},
		created() {
			
		}
	}
</script>

<style scoped>

</style>