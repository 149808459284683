<template>
	<div>
		<live-sport-soccer v-if="sport === 'soccer'"></live-sport-soccer>
		<live-sport-hockey v-if="sport === 'hockey'"></live-sport-hockey>
		<live-sport-tennis v-if="sport === 'tennis'"></live-sport-tennis>
		<live-sport-basketball v-if="sport === 'bsktbl'"></live-sport-basketball>
		<live-sport-mma v-if="sport === 'mma'"></live-sport-mma>
		<live-sport-box v-if="sport === 'box'"></live-sport-box>
	</div>
</template>

<script>
	import LiveSportSoccer from "../components/Sports/Soccer/Pages/LiveSportPage";
	import LiveSportHockey from "../components/Sports/Hockey/Pages/LiveSportPage";
	import LiveSportTennis from "../components/Sports/Tennis/Pages/LiveSportPage";
	import LiveSportBasketball from "../components/Sports/Basketball/Pages/LiveSportPage";
	import LiveSportMma from "../components/Sports/Mma/Pages/LiveSportPage";
	import LiveSportBox from "../components/Sports/Box/Pages/LiveSportPage";

	export default {
		components: {
			LiveSportSoccer,
			LiveSportHockey,
			LiveSportTennis,
			LiveSportBasketball,
			LiveSportMma,
			LiveSportBox
		},
		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>