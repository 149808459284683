<template>
	<div class="timeline">
		<div class="timeline-2">
			<div class="timeline-2_row __header">
				<div class="timeline-2_name"></div>
				
				<div class="timeline-2_score">С</div>
				
				<div class="timeline-2_value">1</div>
				<div class="timeline-2_value">2</div>
				<div class="timeline-2_value">3</div>
				<div class="timeline-2_value">4</div>
				<div class="timeline-2_value">5</div>
			</div>
			<div class="timeline-2_row">
				<div class="timeline-2_name" :class="isPlayer1Win()">{{ player1.name }}</div>
				
				<div class="timeline-2_score" :class="isPlayer1Win()">{{ player1.totalscore }}</div>
				
				<div class="timeline-2_value" v-for="value in 5" :class="isSetWin(player1['s' + value], player2['s' + value])" :key="'player-1' + value" >
					{{ parseInt(player1['s' + value]) || '' }}
				</div>
			</div>
			<div class="timeline-2_row">
				<div class="timeline-2_name" :class="isPlayer2Win()">{{ player2.name }}</div>
				
				<div class="timeline-2_score" :class="isPlayer2Win()">{{ player2.totalscore }}</div>
				
				<div class="timeline-2_value" v-for="value in 5" :class="isSetWin(player2['s' + value], player1['s' + value])" :key="'player-1' + value" >
					{{ parseInt(player2['s' + value]) || '' }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		props: ['match'],
		
		computed: {
			player1() {
				return this.match.player[0];
			},
			player2() {
				return this.match.player[1];
			},
		},
		methods: {
			isPlayer1Win() {
				return {
					__bold: this.player1.totalscore > this.player2.totalscore
				}
			},
			isPlayer2Win() {
				return {
					__bold: this.player2.totalscore > this.player1.totalscore
				}
			},
			isSetWin(score1, score2) {
				return {
					__bold: Number(score1) > Number(score2)
				};
			}
		}
	}
</script>

<style scoped>

</style>