<template>
	<div class="timeline">
		<div>
			<div class="timeline_head" v-if="penalty">Серия Пенальти</div>
			<timeline-base :incidents="penalty" v-if="matchEvent && matchEvent.penalty"></timeline-base>
			<timeline-penalty :incident="penalty" v-else-if="penalty"></timeline-penalty>
			
			<div class="timeline_head" v-if="time3.length">Овертайм</div>
			<timeline-base :incidents="time3" v-if="time3.length"></timeline-base>
			
			<div class="timeline_head" v-if="time2.length">2-й тайм</div>
			<timeline-base :incidents="time2" v-if="time2.length"></timeline-base>
			
			<div class="timeline_head" v-if="time1.length">1-й тайм</div>
			<timeline-base :incidents="time1" v-if="time1.length"></timeline-base>
			
			<div class="timeline_head" v-if="videoList.length">Видео. Голы и лучшие моменты</div>
			<div v-if="videoList" class="video-list">
				<div class="video-list_item" v-for="video in videoList" :key="video.url">
					<iframe v-if="showVideo(video.url)" :src="video.url"></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TimelineBase from "./TimelineBase";
	import TimelinePenalty from "./TimelinePenalty";
	import _ from 'underscore';
	
	export default {
		components: {
			TimelineBase,
			TimelinePenalty
		},
		
		props: ['match', 'videos', 'matchEvent'],
		
		data() {
			return {
				
			}
		},
		
		computed: {
			videoList() {
				if (!this.match || !this.videos) {
					return [];
				}
				
				let id = this.match.static_id;
				let videos = [];
				
				this.videos.forEach((league) => {
					if (Array.isArray(league.matches.match)) {
						league.matches.match.forEach((match) => {
							if (match.static_id == id) {
								if (!Array.isArray(match.videos.item)) {
									videos = [match.videos.item];
								} else {
									videos = match.videos.item;
								}
							}
						});
					} else {
						if (league.matches.match.static_id == id) {
							if (!Array.isArray(league.matches.match.videos.item)) {
								videos = [league.matches.match.videos.item];
							} else {
								videos = league.matches.match.videos.item;
							}
						}
					}
				});
				
				return videos;
			},
			time1() {
				let summary = this.getSummary();
			
				summary = summary.filter((item) => {
					return item.minute < 46;
				});

				summary = _.sortBy(summary, function (item) {
					return -Number(item.minute);
				});
				
				return summary;
			},
			time2() {
				let summary = this.getSummary();
			
				summary = summary.filter((item) => {
					return item.minute >= 46 && item.minute <= 90;
				});

				summary = _.sortBy(summary, function (item) {
					return -Number(item.minute);
				});
				
				return summary;
			},
			time3() {
				let summary = this.getSummary();
			
				summary = summary.filter((item) => {
					return item.minute > 90;
				});

				summary = _.sortBy(summary, function (item) {
					return -Number(item.minute);
				});
				
				return summary;
			},
			penalty() {
				if (this.matchEvent) {
					if (this.matchEvent.penalty) {
						return this.matchEvent.penalty.event;
					}
					return null;
				}
				
				if (!this.match) {
					return;
				}
				
				if (this.match.localteam.pen_score || this.match.visitorteam.pen_score) {
					return {
						localteam: this.match.localteam.pen_score,
						visitorteam: this.match.visitorteam.pen_score,
					}
				}
				
				return null;
			}
		},
		
		created() {
			
		},
		methods: {
			showVideo(url) {
				return !(url.search('mlssoccer.com') >= 0 || url.search('video.goalserve.com') >= 0);
			},
			
			initSubstitutions() {
				let arr = [];
				
				let data = null;
				
				if (this.match.substitutions.localteam) {
					data = this.match.substitutions.localteam.substitution;
					
					if (!Array.isArray(this.match.substitutions.localteam.substitution)) {
						data = [data];
					}
					
					data.forEach((item) => {
						item.team = 'localteam';
						item.substitution = true;
						item.type = 'subst';
						arr.push(item);
					});
				}
				
				if (this.match.substitutions.visitorteam) {
					data = this.match.substitutions.visitorteam.substitution;
					
					if (!Array.isArray(this.match.substitutions.visitorteam.substitution)) {
						data = [data];
					}
					
					data.forEach((item) => {
						item.team = 'visitorteam';
						item.substitution = true;
						item.type = 'subst';
						arr.push(item);
					});
				}
				
				return arr;
			},
			getSummary() {
				if (this.matchEvent) {
					return this.matchEvent.game_events;
				}
				
				return this.match.summary.concat(this.initSubstitutions())
			}
		},
	}
</script>

<style scoped>

</style>