<template>
	<div class="match_left">
		<div class="match_menu">
			<div class="match_button" @click="menuActive = true">
				<span></span>
				<i data-v-9b8449ee="" class="ico icon-side-arrow"></i>
			</div>
			
			<div class="match_menu-inner" :class="{ active: menuActive }">
				<div class="header_menu-top match_menu-top">
					<div class="header_menu-close" @click="menuActive = false"></div>
				</div>
				<router-link :to="{ name: 'league', params: {sport: 'box', id: category} }" class="match_menu-item" v-for="category in data" :key="category">
					<span class="league-name">{{ $t('sports.box.categories.' + category) }}</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		data() {
			return {
				menuActive: false
			}
		},
		mounted() {
			this.initTitle();
		},
		methods: {
			initTitle() {
				var text = $('.match_menu-item.router-link-active').html();

				if (text && text.length) {
					$('.match_button span').html('').append(text);
				} else {
					$('.match_button span').html('Лиги');
				}
			},
		}
	}
</script>

<style scoped>

</style>