<template>
	<div class="match">
		<div class="wrapper" v-if="match">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div class="live-command">
							<div class="live-command_date">
								{{ getDateTime(match.date, match.time) }}
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'player', params: {id: player1.id, sport: 'tennis'}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/tennis/player/image?id=' + player1.id" alt="">
									<span>{{ player1.name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ player1.totalscore }} -&nbsp; {{ player2.totalscore }}
									</div>
									<div class="live-command_res">
										<div v-if="match.status === 'Finished'">ЗАВЕРШЕН</div>
										<div v-else-if="match.status === 'Not Started'">МАТЧ НЕ НАЧАЛСЯ</div>
										<div v-else-if="match.status === 'Cancl.'">ОТМЕНЕН</div>
										<div v-else-if="match.status === 'Postp.'">ОТЛОЖЕН</div>
									</div>
								</div>
								
								<router-link :to="{name: 'player', params: {id: player2.id, sport: 'tennis'}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/tennis/player/image?id=' + player2.id" alt="">
									<span>{{ player2.name }}</span>
								</router-link>
							</div>
						</div>
						
						<tab :headings="{timeline: 'Обзор',  h2h: 'Последние матчи'}" active-key="timeline">
							<template v-slot:timeline>
								<timeline v-if="match" :match="match"></timeline>
							</template>
							<template v-slot:stat>
								<statistic v-if="statistic" :data="statistic"></statistic>
								<div v-else class="info">Нет данных</div>
							</template>
							<template v-slot:h2h>
								<total-results v-if="h2h" :h2h="h2h" :match="match"></total-results>
								
								<last-results v-if="h2h" :match="match" :results="h2h"></last-results>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">Информация обновляется</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	
	import Tab from "../../../Tab/Tab";
	import Statistic from "../Components/Match/Statistic";
	import Timeline from "../Components/Match/Timeline";
	import LastResults from "../Components/Match/LastResults";
	import TotalResults from "../Components/Match/TotalResults";
	
	export default {
		components: {
			Statistic,
			Tab,
			Timeline,
			LastResults,
			TotalResults
		},
		
		data() {
			return {
				match: null,
				h2h: null,
				loading: true,
				statistic: null
			}
		},
		
		created() {
			let id = this.$route.params.id;
			
			this.axios.get('/goalserve/tennis/league/events?id=' + id)
				.then(({data}) => {
					this.match = data.res ? data.res[0].events[0] : null;
					this.loading = false;
					
					if (!this.match) return;
					
					this.axios.get('/goalserve/tennis/scores/h2h?player1=' + this.match.player[0].id + '&player2=' + this.match.player[1].id)
						.then(({data}) => {
							this.h2h = data.res;
						});
				});
			
			this.axios.get('/goalserve/tennis/league/gamestat')
				.then(({data}) => {
					this.statistic = data.res;
				});
		},
		
		computed: {
			player1() {
				if (!this.match) {
					return null;
				}
				return this.match.player[0];
			},
			player2() {
				if (!this.match) {
					return null;
				}
				return this.match.player[1];
			}
		},
		methods: {
			getDateTime(date, time) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';
				
				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];
				
				return moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours').format('DD.MM.Y HH:mm');
			},
		}
	}
</script>