import { render, staticRenderFns } from "./PlayerPage.vue?vue&type=template&id=478fe369&scoped=true&"
import script from "./PlayerPage.vue?vue&type=script&lang=js&"
export * from "./PlayerPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478fe369",
  null
  
)

export default component.exports