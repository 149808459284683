<template>
	<div>
		<banner type="main"></banner>
		
		<div class="tab __pb __pt">
			<div class="wrapper">
				<h1 class="header-block_title">Лига Наций УЕФА</h1>
				
				<tab :headings="{schedule: 'Расписание', news: 'Новости', results: 'Результаты', standings: 'Таблица', scorers: 'Бомбардиры'}" active-key="schedule">
					<template v-slot:schedule>
						<links v-if="schedule" sport="soccer" :data="schedule" :show-date="true"></links>
						<div v-else-if="!scheduleLoading" class="info">Нет данных</div>
					</template>
					<template v-slot:news>
						<div class="last-news __pt">
							<div class="last-news_row" v-for="newsItem in news" :key="newsItem.id">
								<router-link :to="{ name: 'news-item', params: {id: newsItem.id} }" class="last-news_title">
									{{ newsItem.title }}
								</router-link>
								
								<div class="last-news_date">
									{{ getTime(newsItem.pub_date) }}
								</div>
							</div>
						</div>
					</template>
					<template v-slot:results>
						<links v-if="results" sport="soccer" :data="results" :show-date="true"></links>
						<div v-else class="info">Нет данных</div>
					</template>
					<template v-slot:standings>
						<standings v-if="standings" :data="standings"></standings>
						<div v-else class="info">Нет данных</div>
					</template>
					<template v-slot:scorers>
						<scorers v-if="scorers" :data="scorers"></scorers>
						<div v-else class="info">Нет данных</div>
					</template>
				</tab>
			</div>
		</div>
	
		<news sport="soccer"></news>
		
		<videos sport="soccer"></videos>
	
		<games sport="soccer"></games>
	</div>
</template>

<script>
	import moment from 'moment';
	import News from "../components/News/News";
	import Videos from "../components/Videos/Videos";
	import Games from "../components/Games/Games";
	import Banner from "../components/Layout/Banner";
	import Tab from "../components/Tab/Tab";
	import Standings from "../components/Sports/Soccer/Components/Events/Standings";
	import Scorers from "../components/Sports/Soccer/Components/Events/Scorers";
	import Links from "../components/Sports/Soccer/Components/Events/Links";
	
	export default {
		components: {
			News,
			Videos,
			Games,
			Banner,
			Tab,
			Standings,
			Scorers,
			Links
		},
		
		data() {
			return {
				news: [],
				schedule: null,
				scheduleLoading: true,
				results: null,
				standings: null,
				scorers: null
			}
		},
		
		created() {
			this.initEvents();
		},
		
		
		methods: {
			initEvents() {
				let leagueId = 4529;
				
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=finished&league_id=' + leagueId)
					.then(({data}) => {
						this.results = data.res;
					});
				
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=not_finished&league_id=' + leagueId)
					.then(({data}) => {
						this.schedule = data.res ? data.res.slice(0, 1) : null;

						if (this.schedule) {
							this.schedule.forEach((schedule) => {
								schedule.group_prefix = '';
								schedule.group_suffix = '';
							});
						}
					});
				
				this.axios.get('/goalserve/soccer/league/standings?league_id=' + leagueId)
					.then(({data}) => {
						this.standings = data.res ? data.res : null;
					});
				
				this.axios.get('/goalserve/soccer/league/topscorers?league_id=' + leagueId)
					.then(({data}) => {
						this.scorers = data.res;
					});

				this.axios.get('/api/news?rubric=1882')
					.then(({data}) => {
						this.news = data.data;
					});
			},
			
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm');
			},
		}
	}
</script>
