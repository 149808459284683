<template>
	<div>
		<div v-if="players.length" class="live-commands_header">Стартовые составы</div>
		<lineup-base v-if="players.length" :data="players"></lineup-base>
		<div v-else class="info">Нет данных</div>
		
		<div v-if="substitutes.length" class="live-commands_header">Замены</div>
		<lineup-base v-if="substitutes.length" :data="substitutes"></lineup-base>
		
		<div v-if="coaches.length" class="live-commands_header">Тренеры</div>
		<lineup-base v-if="coaches.length" :data="coaches"></lineup-base>
	</div>
</template>

<script>
	import LineupBase from "./LineupBase";

	export default {
		components: {
			LineupBase
		},

		props: ['match'],

		computed: {
			players() {
				if (!this.match.teams) {
					return {
						length: 0
					}
				}

				return {
					home: this.match.teams.localteam.player,
					away: this.match.teams.visitorteam.player,
					length: this.match.teams.localteam.player.length
				}
			},
			substitutes() {
				if (!this.match.substitutes) {
					return {
						length: 0
					}
				}

				return {
					home: this.match.substitutes.localteam.player,
					away: this.match.substitutes.visitorteam.player,
					length: this.match.substitutes.localteam.player.length
				}
			},
			coaches() {
				if (!this.match.coaches) {
					return {
						length: 0
					}
				}

				return {
					home: [this.match.coaches.localteam.coach],
					away: [this.match.coaches.visitorteam.coach],
					length: 1
				}
			},
		},

		created() {

		}
	}
</script>

<style scoped>

</style>