<template>
	<div>
		<team-page-soccer v-if="sport === 'soccer'"></team-page-soccer>
		<team-page-basketball v-if="sport === 'bsktbl'"></team-page-basketball>
	</div>
</template>

<script>
	import TeamPageSoccer from "../components/Sports/Soccer/Pages/TeamPage";
	import TeamPageBasketball from "../components/Sports/Basketball/Pages/TeamPage";
	
	export default {
		components: {
			TeamPageSoccer,
			TeamPageBasketball
		},
		
		data() {
			return {
				sport: this.$route.params.sport,
			}
		},
	}
</script>

<style scoped>

</style>