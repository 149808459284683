<template>
	<div>
		<articles :sport="sport"></articles>
		
		<news :sport="sport"></news>
	</div>
</template>

<script>
	import NewsIndex from "./NewsIndex";
	import ArticleIndex from "./ArticleIndex";
	
	export default {
		props: ['sport'],
		
		components: {
			News: NewsIndex,
			Articles: ArticleIndex
		},
		
		data() {
			return {
				newsIsActive: false,
				news: null,
				articles: null
			}
		},
		
		computed: {
			newsLink() {
				return this.sport ? '/news?page=1&sport=' + this.sport : '/news';
			},
			articlesLink() {
				return this.sport ? '/articles?page=1&sport=' + this.sport : '/articles';
			}
		}
	}
</script>

<style scoped>

</style>