<template>
	<div>
		<team-page-hockey></team-page-hockey>
	</div>
</template>

<script>
	import TeamPageHockey from "../components/Sports/Hockey/Pages/TeamPage";
	
	export default {
		components: {
			TeamPageHockey
		},
		
		data() {
			return {
				
			}
		},
	}
</script>

<style scoped>

</style>