<template>
	<div class="match">
		<div class="wrapper">
			<div class="match_heading">События MMA</div>
			
			<div class="match_row">
				<div class="match_left">
					<div class="match_menu-inner">
						<div class="match_menu-item">
							<span class="league-name">Все лиги</span>
						</div>
					</div>
				</div>
				
				<div class="match_right">
					<div class="tab">
						<match-header></match-header>
						
						<tab :headings="{active : 'Последние', finished: 'Завершенные', schedule: 'Расписание'}" active-key="active" :base="true">
							<template v-slot:active>
								<links v-if="events" :data="events"></links>
								<div v-else-if="eventsLoading" class="info-text"></div>
								<div v-else class="info">Нет данных</div>
							</template>
							<template v-slot:finished>
								<list v-if="finished" :data="finished"></list>
								<div v-else-if="finishedLoading" class="info-text"></div>
								<div v-else class="info">Нет данных</div>
							</template>
							<template v-slot:schedule>
								<list v-if="schedule" :data="schedule"></list>
								<div v-else-if="scheduleLoading" class="info-text"></div>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MatchHeader from '../../Common/MatchHeader';
	import Tab from "../../../Tab/Tab";
	import Links from "../Components/Links";
	import List from "../Components/List";
	

	export default {
		components: {
			Links,
			List,
			MatchHeader,
			Tab
		},

		data() {
			return {
				events: null,
				eventsLoading: true,
				finished: null,
				finishedLoading: true,
				schedule: null,
				scheduleLoading: true,
			}
		},

		created() {
			this.initEvents();
			
			this.initFinished();
			
			this.initSchedule();
		},

		methods: {
			initEvents() {
				this.axios.get('/goalserve/mma/live')
					.then(({data}) => {
						this.events = data.res;
						this.eventsLoading = false;
					});
			},
			initFinished() {
				this.axios.get('/goalserve/mma/schedule?status=finished')
					.then(({data}) => {
						this.finished = data.res.reverse();
						this.finishedLoading = false;
					});
			},
			initSchedule() {
				this.axios.get('/goalserve/mma/schedule?status=not_finished')
					.then(({data}) => {
						this.schedule = data.res;
						this.scheduleLoading = false;
					});
			},
		}
	}
</script>

<style scoped>

</style>