<template>
	<div class="match_items">
		<div v-for="standingGroup in standings" :key="standingGroup.name">
			<div class="match_item">
				<div class="match_item-content">
					<div class="match_item-header active">
						<span>{{ standingGroup.name }}</span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="live-table_content">
							<div class="live-table_row">
								<div class="live-table_col __1"></div>
								<div class="live-table_col __2">
									<div class="live-table_name">Команда</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name">И</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name">В</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name">П</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name">О</div>
								</div>
							</div>
							<div class="live-table_data">
								<div class="live-table_row" v-for="standing in standingGroup.league.team" :key="'standing' + standing.id">
									<div class="live-table_col __1">
										<div class="live-table_num ">{{ standing.pos }}</div>
									</div>
									<div class="live-table_col __2">
										<div class="live-table_main">
											<router-link :to="{ name: 'team', params: {id: standing.id} }" class="live-table_name-main" href="">
												{{ standing.name }}
											</router-link>
										</div>
									</div>
									<div class="live-table_col __3 __mobile-hidden">
										<div class="live-table_name-2">
											{{ standing.gp }}
										</div>
									</div>
									<div class="live-table_col __3 __mobile-hidden">
										<div class="live-table_name-2">
											{{ standing.w }}
										</div>
									</div>
									<div class="live-table_col __3">
										<div class="live-table_name-2">
											{{ standing.l }}
										</div>
									</div>
									<div class="live-table_col __3">
										<div class="live-table_name-2">
											{{ standing.pts }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		computed: {
			standings() {
				return this.data.slice(0, 1);
			}
		}
	}
</script>

<style scoped>

</style>