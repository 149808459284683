<template>
	<div class="match">
		<div class="wrapper">
			<div class="match_heading">События {{ activeDay.defaultValue }}</div>
			
			<div class="match_row">
				<leagues v-if="leagues" :sport="sport" :data="leagues"></leagues>
				
				<div class="match_left" v-else>
					<div class="info-text"></div>
				</div>
				
				<div class="match_right">
					<div class="tab">
						<match-header></match-header>
						
						<list :data="matchesData" :loading="loading"></list>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Leagues from "../../Common/Leagues";
	import MatchHeader from "../../Common/MatchHeader";
	import List from "../Components/Events/List";

	export default {
		components: {
			Leagues,
			MatchHeader,
			List
		},

		data() {
			return {
				sport: this.$route.params.sport,
				leagues: null,
				matchesData: null,
				activeDay: {},
				loading: false
			}
		},

		created() {
			this.initLeagues();

			this.loading = true;
			this.update();

			events.$on('activeDay', this.initDay);
		},

		methods: {
			initData() {
				let day = this.$route.query.day_diff || 0;
				let url = '/goalserve/' + this.sport + '/league/events';

				if (day !== 0) {
					url += '?day_diff=' + day;
				}

				this.axios.get(url)
					.then(({data}) => {
						this.matchesData = this.sortData(data.res);
	
						this.loading = false;
					});
			},
			initLeagues() {
				this.axios.get('/goalserve/' + this.sport + '/league')
				.then(({data}) => {
					this.leagues = data;
				});
			},
			initDay(day) {
				this.activeDay = day;
			},
			update() {
				if (this.sport !== this.$route.params.sport) return;

				this.initData();

				setTimeout(() => {
					this.update();
				}, 1000 * 60);
			},
			sortData(data) {
				if (!data) {
					return null;
				}

				let leagues = [1005, 1007, 1204, 1229, 1399, 1269, 1457, 1221,1428, 4529];

				leagues.reverse().forEach((id) => {
					data = data.sort((a, b) => {
						if (a.league_id == id) {
							return -1;
						}
						if (b.league_id == id) {
							return 1;
						}
					});
				});
				
				return data;
			}
		}
	}
</script>

<style scoped>

</style>