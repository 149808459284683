<template>
	<!-- eslint-disable -->
	
	<div class="match">
		<div class="wrapper">
			<div class="match_row">
				<leagues v-if="leagues" sport="soccer" :data="leagues"></leagues>
				
				<div class="match_right" v-if="teamInfo">
					<div class="team">
						<div class="team_main">
							<div class="team_image">
								<img :src="'data:image/png;base64, ' + teamInfo.image" alt="">
							</div>
							<div class="team_name">{{ teamInfo.name }}</div>
						</div>
						
						<tab :headings="{results: 'Результаты', schedule: 'Календарь'}" active-key="results">
							<template v-slot:results>
								<links v-if="results" :sport="sport" :data="results" :show-date="true"></links>
								<div v-else class="info">Нет данных</div>
							</template>
							
							<template v-slot:schedule>
								<links v-if="schedule" :sport="sport" :data="schedule" :show-date="true"></links>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'underscore';
	
	import Leagues from "../../Common/Leagues";
	import Tab from "../../../Tab/Tab";
	import Links from "../Components/Events/Links";
	
	export default {
		components: {
			Leagues,
			Tab,
			Links,
		},
		
		data() {
			return {
				id: this.$route.params.id,
				leagues: null,
				teamInfo: null,
				sport: this.$route.params.sport,
				results: null,
				schedule: null
			}
		},
		
		computed: {
			players() {
				if (!this.team.squad.player) {
					return null
				}
				return _.groupBy(this.team.squad.player, (item) => {
					return item.position;
				});
			},
			transfers() {
				return this.team.transfers || null;
			},
		},
		
		created() {
			this.initLeagues();
			this.initTeamInfo();
			this.initResults();
		},
		
		methods: {
			initLeagues() {
				this.axios.get('/goalserve/soccer/league')
					.then(({data}) => {
						this.leagues = data;
					});
			},
			initTeamInfo() {
				this.axios.get('/goalserve/bsktbl/team/rosters?team_id=' + this.id)
					.then(({data}) => {
						this.teamInfo = data.res;
					});
			},
			initResults() {
				this.axios.get('/goalserve/bsktbl/league/events?&team_id=' + this.id)
					.then(({data}) => {
						this.results = data.res;
					});
			},
		}
	}
</script>