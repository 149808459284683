<template>
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="(event, index) in matches" :key="'matches-' + index">
				<div>
					<div class="match_item-header active">
						<span v-text="event.name"></span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<div v-for="match in getMatches(event.match)" class="match-data_item" :key="match.id">
								<div class="match-data_row">
									<div class="match-data_col-1">{{ match.date }}</div>
									
									<div class="match-data_col-2" :class="{__bold: match.localteam.winner === 'True'}">
										<span v-text="match.localteam.name"></span>
									</div>
									
									<div class="match-data_col-4">-</div>
									
									<div class="match-data_col-6" :class="{__bold: match.awayteam.winner === 'True'}">
										<span v-text="match.awayteam.name"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		computed: {
			matches() {
				return Array.isArray(this.data) ? this.data : [this.data];
			}
		},
		created() {
			console.log(this.matches);
		},
		methods: {
			getMatches(matches) {
				return Array.isArray(matches) ? matches : [matches];
			}
		}
	}
</script>

<style scoped>

</style>