<template>
	<div>
		<div>
			<div class="tab_header">
				<a @click.prevent="change(item.id)" :href="item.id" :class="{'router-link-active' : categoryValue == item.id}" v-for="item in items" :key="item.id">
					{{ item.title }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['category'],
		
		data() {
			return {
				items: null,
				categoryValue: this.category || ''
			}
		},
		methods: {
			change(value) {
				this.$emit('update', value);
			}
		},
		
		created() {
			this.axios.get('/api/videos/categories').then(({data}) => {
				this.items = data;
			});
		}
	}
</script>

<style scoped>

</style>