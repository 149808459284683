<template>
	<div>
		<match-page></match-page>
	</div>
</template>

<script>
	import MatchPage from "../components/Sports/Mma/Pages/MatchPage";
	
	export default {
		components: {
			MatchPage
		}
	}
</script>

<style scoped>

</style>