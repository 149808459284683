<template>
	<!-- eslint-disable -->
	
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="group in matches">
				<div>
					<div class="match_item-header active">
						<span v-text="group.name"></span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<router-link v-for="match in group.match"
										 class="match-data_item" 
										 :to="{ name: 'event-tennis', params: {id: match.id,  sport: 'tennis'} }">
								<link-tennis :match="match"></link-tennis>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LinkTennis from "./Link";
	
	export default {
		components: {
			LinkTennis
		},
		
		props: ['data'],
		
		computed: {
			matches() {
				let res = JSON.parse(JSON.stringify(this.data));
				
				res.forEach((item) => {
					if (typeof item.match === 'object') {
						item.match = [item.match];
					}
				});
				
				res = res.filter((item) => {
					return !Array.isArray(item.match[0]);
				});
				
				return res;
			},
		},
		
		methods: {
			
		}
	}
</script>

<style scoped>

</style>