<template>
	<!-- eslint-disable -->
	
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="group in matches">
				<link-results-group :group="group"></link-results-group>
			</div>
		</div>
	</div>
</template>

<script>
	import LinkResultsGroup from "./LinkResultsGroup";
	
	export default {
		components: {
			LinkResultsGroup
		},
		
		props: ['data', 'showDate'],
		
		data() {
			return {
				page: 1
			}
		},
		
		computed: {
			matches() {
				return JSON.parse(JSON.stringify(this.data));
			},
		},
	}
</script>

<style scoped>

</style>