<template>
	<div>
		<div class="timeline_row">
			<div class="timeline_left">
				<div class="timeline_name __color-2">{{ incident.localteam }}</div>
			</div>
			<div class="timeline_center">
				<div class="timeline_ball"></div>
			</div>
			<div class="timeline_right">
				<div class="timeline_name __color-2">{{ incident.visitorteam }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['incident'],
	}
</script>

<style scoped>

</style>