<template>
	<div>
		<div class="content-block" v-if="player">
			<div class="wrapper">
				<div class="player-card">
					<h2 class="player-card_title">{{ player.name }}</h2>
					<div class="player-card_main">
						<div class="player-card_image">
							<img :src="'data:image/png;base64, ' + player.image" alt="">
						</div>
						<div class="player-card_content">
							<h3>Досье</h3>
							
							<div class="player-card_row">
								<div class="player-card_col">
									<table class="player-card_table">
										<tbody>
											<tr>
												<td>Имя</td>
												<td>
													<strong>{{ player.firstname }}</strong>
												</td>
											</tr>
											<tr>
												<td>Фамилия</td>
												<td>
													<strong>{{ player.common_name }}</strong>
												</td>
											</tr>
											<tr>
												<td>Дата рождения</td>
												<td>
													<strong>{{ player.birthdate }}</strong>
												</td>
											</tr>
											<tr>
												<td>Страна</td>
												<td>
													<strong>{{ player.birthcountry }}</strong>
												</td>
											</tr>
											<tr>
												<td>Город</td>
												<td>
													<strong>{{ player.birthplace }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="player-card_col">
									<table class="player-card_table">
										<tbody>
											<tr>
												<td>Возраст</td>
												<td>
													<strong>{{ player.age }}</strong>
												</td>
											</tr>
											<tr>
												<td>Рост</td>
												<td>
													<strong>{{ player.height }}</strong>
												</td>
											</tr>
											<tr>
												<td>Вес</td>
												<td>
													<strong>{{ player.weight }}</strong>
												</td>
											</tr>
											<tr>
												<td>Амплуа</td>
												<td>
													<strong>{{ player.position }}</strong>
												</td>
											</tr>
											<tr>
												<td>Команда</td>
												<td>
													<strong>{{ player.team }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<br>
				
				<div class="commands-block">
					<h3>Статистика</h3>
					
					<table class="commands-block_table">
						<tbody>
							<tr>
								<th>Команда</th>
								<th>И</th>
								<th>Г</th>
								<th>ГП</th>
								<th>
									<svg width="21" height="26" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 26"
										 id="f-card-y">
										<g fill="none" fill-rule="evenodd">
											<path
											d="M0 3a3 3 0 0 1 3-3h15a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3zm3 0v20h15V3H3z"
											fill="#FFF" fill-rule="nonzero"></path>
											<path fill="#FFC933" d="M3 3h15v20H3z"></path>
										</g>
									</svg>
								</th>
								<th>
									<svg width="21px" height="26px" viewBox="0 0 21 26">
										<svg width="21" height="26" xmlns="http://www.w3.org/2000/svg"
											 viewBox="0 0 21 26" id="f-card-yr">
											<g fill="none" fill-rule="evenodd">
												<path
												d="M0 3a3 3 0 0 1 3-3h15a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3zm3 0v20h15V3H3z"
												fill="#FFF" fill-rule="nonzero"></path>
												<path fill="#FFC933" d="M3 3h15v20H3z"></path>
												<path fill="#E73B34" d="M3 3h15L3 23z"></path>
												<path fill="#E73B34" d="M3 3h15L3 23z"></path>
											</g>
										</svg>
									</svg>
								</th>
								<th>
									<svg width="21px" height="26px" viewBox="0 0 21 26">
										<svg width="21" height="26" xmlns="http://www.w3.org/2000/svg"
											 viewBox="0 0 21 26" id="f-card-r">
											<g fill="none" fill-rule="evenodd">
												<path
												d="M0 3a3 3 0 0 1 3-3h15a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3zm3 0v20h15V3H3z"
												fill="#FFF" fill-rule="nonzero"></path>
												<path fill="#E73B34" d="M3 3h15v20H3z"></path>
											</g>
										</svg>
									</svg>
								</th>
								<th>
									<svg width="26px" height="26px" viewBox="0 0 26 26">
										<svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"
											 viewBox="0 0 26 26" id="change-in">
											<g fill="none">
												<path
												d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13z"
												fill="#FFF"></path>
												<path
												d="M15.978 14.95c0 .28-.043.543-.13.79a1.92 1.92 0 0 1-.38.65 1.802 1.802 0 0 1-.62.445c-.247.11-.533.165-.86.165h-3.23v-6.89h3.1c.347 0 .645.05.895.15.25.1.457.237.62.41.163.173.285.373.365.6.08.227.12.463.12.71 0 .253-.068.513-.205.78a1.403 1.403 0 0 1-.645.62c.167.08.31.182.43.305a1.902 1.902 0 0 1 .54 1.265zm-1.53-2.84c0-.253-.075-.447-.225-.58-.15-.133-.385-.2-.705-.2h-1.39v1.51h1.41c.287 0 .51-.067.67-.2.16-.133.24-.31.24-.53zm.08 2.8c0-.267-.083-.482-.25-.645-.167-.163-.397-.245-.69-.245h-1.46v1.76h1.47c.307 0 .538-.078.695-.235.157-.157.235-.368.235-.635z"
												fill="#06CA9A"></path>
												<path
												d="M22.306 12.698l-1.647.56a7.5 7.5 0 0 1-7.508 7.411 7.484 7.484 0 0 1-4.302-1.368l1.813-1.692-1.555-.423-2.687-.731-1.746-.475.233 1.167.274 1.371.709 3.541 1.726-1.61a9.116 9.116 0 0 0 7.341 1.705 9.106 9.106 0 0 0 5.834-3.903 9.093 9.093 0 0 0 1.515-5.553zM11.689 5.813a7.48 7.48 0 0 1 5.64 1.123L15.408 8.67l1.834.47 2.627.672 1.738.446-.23-1.149-.256-1.288-.73-3.646-1.8 1.624a9.114 9.114 0 0 0-7.229-1.62 9.104 9.104 0 0 0-5.829 3.903 9.09 9.09 0 0 0-1.518 5.555l1.647-.56a7.5 7.5 0 0 1 6.028-7.264z"
												fill="#06CA9A"></path>
											</g>
										</svg>
									</svg>
								</th>
								<th>
									<svg width="26" height="26" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"
										 id="change-out">
										<g fill="none">
											<path
											d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13z"
											fill="#FFF"></path>
											<path
											d="M15.978 14.95c0 .28-.043.543-.13.79a1.92 1.92 0 0 1-.38.65 1.802 1.802 0 0 1-.62.445c-.247.11-.533.165-.86.165h-3.23v-6.89h3.1c.347 0 .645.05.895.15.25.1.457.237.62.41.163.173.285.373.365.6.08.227.12.463.12.71 0 .253-.068.513-.205.78a1.403 1.403 0 0 1-.645.62c.167.08.31.182.43.305a1.902 1.902 0 0 1 .54 1.265zm-1.53-2.84c0-.253-.075-.447-.225-.58-.15-.133-.385-.2-.705-.2h-1.39v1.51h1.41c.287 0 .51-.067.67-.2.16-.133.24-.31.24-.53zm.08 2.8c0-.267-.083-.482-.25-.645-.167-.163-.397-.245-.69-.245h-1.46v1.76h1.47c.307 0 .538-.078.695-.235.157-.157.235-.368.235-.635z"
											fill="#E73B34"></path>
											<path
											d="M22.306 12.698l-1.647.56a7.5 7.5 0 0 1-7.508 7.411 7.484 7.484 0 0 1-4.302-1.368l1.813-1.692-1.555-.423-2.687-.731-1.746-.475.233 1.167.274 1.371.709 3.541 1.726-1.61a9.116 9.116 0 0 0 7.341 1.705 9.106 9.106 0 0 0 5.834-3.903 9.093 9.093 0 0 0 1.515-5.553zM11.689 5.813a7.48 7.48 0 0 1 5.64 1.123L15.408 8.67l1.834.47 2.627.672 1.738.446-.23-1.149-.256-1.288-.73-3.646-1.8 1.624a9.114 9.114 0 0 0-7.229-1.62 9.104 9.104 0 0 0-5.829 3.903 9.09 9.09 0 0 0-1.518 5.555l1.647-.56a7.5 7.5 0 0 1 6.028-7.264z"
											fill="#E73B34"></path>
										</g>
									</svg>
								</th>
								<th>Мин</th>
							</tr>
							
							
							<tr v-for="(row, key) in player.statistic.club" :key="key">
								<td>
									<div>{{ row.name }}</div>
								</td>
								<td>{{ row.appearences }}</td>
								<td>{{ row.goals }}</td>
								<td>{{ row.assists }}</td>
								<td>{{ row.yellowcards }}</td>
								<td>{{ row.yellowred }}</td>
								<td>{{ row.redcards }}</td>
								<td>{{ row.substitute_in }}</td>
								<td>{{ row.substitute_out }}</td>
								<td>{{ row.minutes }}</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div v-else-if="loading" class="info-text"></div>
		<div class="content-block" v-else>
			<div class="wrapper">
				<div class="info __pt0">Нет данных</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				player: null,
				loading: true
			}
		},
		created() {
			this.axios.get('/goalserve/soccer/player/' + this.$route.params.id)
				.then(({data}) => {
					this.player = data.res;
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
				});
		}
	}
</script>

<style scoped>

</style>