<template>
	<div></div>
</template>

<script>
	export default {
		created() {
			this.axios.get('/a1/logout').then(({data}) => {
				location.href = '/';
			})
		}
	}
</script>

<style scoped>

</style>