<template>
	<div class="block __dark" v-if="articles && articles.length">
		<div class="wrapper">
			<div class="header-block">
				<h2 class="header-block_title">Статьи</h2>
				
				<router-link :to="articlesLink" class="header-block_link">Все статьи</router-link>
			</div>
			
			<div class="news-block">
				<div>
					<div class="news-block_item __not-first"  v-for="article in articles" :key="article.id">
						<router-link :to="{ name: 'article', params: { id: article.id } }" class="news-block_image">
							<img :src="article.image_ref" alt="">
							<div class="main-news-2_partner-logo"></div>
						</router-link>
						
						<div class="news-block_content">
							<div class="news-block_time __top">{{ getNewsTime(article.time) }}</div>
							
							<router-link :to="{ name: 'article', params: { id: article.id } }" class="news-block_title">
								{{ article.title}}
							</router-link>
							
							<div class="news-block_time">
								<span>{{ getNewsTime(article.time) }}</span>
								
								<router-link :to="{ name: 'article', params: { id: article.id } }">Подробнее</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['sport'],

		data() {
			return {
				articles: null,
				options: {
					arrows: false,
					dots: true
				}
			}
		},
		computed: {
			articlesLink() {
				return this.sport ? '/articles?page=1&sport=' + this.sport : '/articles';
			}
		},
		created() {
			let params = {
				limit: 3, page: 1
			};

			if (this.sport) {
				params.sport = this.sport;
			}

			this.axios.get('/api/articles', {params: params})
				.then(({data}) => {
					this.articles = data.data;
				});
		},

		methods: {
			getNewsTime(time) {
				return moment(time).locale("ru").format('D MMMM Y, HH:mm')
			},
		}
	}
</script>

<style scoped>

</style>
