<template>
	<div>
		<league-page-tennis></league-page-tennis>
	</div>
</template>

<script>
	import LeaguePageTennis from "../components/Sports/Tennis/Pages/LeaguePage";
	
	export default {
		components: {
			LeaguePageTennis
		}
	}
</script>

<style scoped>

</style>