<template>
	<div class="live-commands">
		<div class="live-commands_row" v-for="(val, index) in data.length" :key="index">
			<div class="live-commands_col">
				<div class="live-commands_table">
					<div class="live-commands_1">
						<span v-if="data.home[index]">{{ data.home[index].number || '' }}</span>
					</div>
					<div class="live-commands_3">
						<img v-if="data.home[index]" :src="$hostname + '/goalserve/soccer/team_image/' + data.home[index].id" alt="">
					</div>
					<div class="live-commands_4">
						{{ data.home[index].name }}
					</div>
				</div>
			</div>
			<div class="live-commands_col">
				<div class="live-commands_table">
					<div class="live-commands_1">
						<span v-if="data.away[index].number">{{ data.away[index].number || '' }}</span>
					</div>
					<div class="live-commands_3">
						<img v-if="data.away[index]" :src="$hostname + '/goalserve/soccer/team_image/' + data.away[index].id" alt="">
					</div>
					<div class="live-commands_4">
						{{ data.away[index].name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
	}
</script>

<style scoped>

</style>