<template>
	<div class="match_menu rank-list">
		<h2>Рейтинг</h2>
		
		<div class="match_button" @click="menuActive = true">
			<span></span>
			<i data-v-9b8449ee="" class="ico icon-side-arrow"></i>
		</div>
		
		<div class="match_menu-inner" :class="{ active: menuActive }">
			<div class="header_menu-top match_menu-top">
				<div class="header_menu-close" @click="menuActive = false"></div>
			</div>
			<router-link :to="{ name: 'tennis-rank', params: {type: 'atp'} }" class="match_menu-item">
				<span class="league-name">ATP - Одиночный</span>
			</router-link>
			<router-link :to="{ name: 'tennis-rank', params: {type: 'wta'} }" class="match_menu-item">
				<span class="league-name">WTA - Одиночный</span>
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menuActive: false,
			}
		},
		
		created() {
			this.initTitle();
		},
		
		computed: {
			
		},
		methods: {
			initTitle() {
				setTimeout(() => {
					var text = $('.rank-list .match_menu-item.router-link-active span').text();
					
					if (text.length) {
						$('.rank-list .match_button span').html('').append(text);
					} else {
						$('.rank-list .match_button span').html('Рейтинги');
					}
				}, 200);
			},
		},
	}
</script>