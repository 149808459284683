<template>
	<div>
		<league-page-soccer v-if="sport === 'soccer'"></league-page-soccer>
		<league-page-hockey v-if="sport === 'hockey'"></league-page-hockey>
		<league-page-basketball v-if="sport === 'bsktbl'"></league-page-basketball>
		<category-page-box v-if="sport === 'box'"></category-page-box>
	</div>
</template>

<script>
	import LeaguePageSoccer from "../components/Sports/Soccer/Pages/LeaguePage";
	import LeaguePageHockey from "../components/Sports/Hockey/Pages/LeaguePage";
	import LeaguePageBasketball from "../components/Sports/Basketball/Pages/LeaguePage";
	import CategoryPageBox from "../components/Sports/Box/Pages/CategoryPage";
	
	export default {
		components: {
			LeaguePageSoccer,
			LeaguePageHockey,
			LeaguePageBasketball,
			CategoryPageBox
		},
		
		data() {
			return {
				sport: this.$route.params.sport,
			}
		},
	}
</script>