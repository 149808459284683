<template>
	<div v-if="matches && Object.keys(matches).length">
		<div class="block">
			<div class="wrapper">
				<div>
					<div class="header-block">
						<h2 class="header-block_title" v-if="title">{{ title }}</h2>
						
						<router-link :to="{name: 'live-sport', params: {sport: slug}}" class="header-block_link">
							Все матчи
						</router-link>
					</div>
					
					<links v-if="matches" sport="soccer" :data="matches"></links>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Links from "../Sports/Basketball/Components/Events/Links";
	
	export default {
		components: {
			Links
		},
		
		props: ['matches', 'title', 'slug'],
		
		data() {
			return {
				slickOptions: {
					prevArrow: '<div class="ico icon-side-arrow slick-prev"></div>',
					nextArrow: '<div class="ico icon-side-arrow slick-next"></div>',
				},
			}
		},
		
		methods: {
			goToMatch(id) {
				this.$router.push({
					name: 'event',
					params: {
						id: id
					}
				});
			},
		}
	}
</script>