<template>
	<div class="block">
		<div class="wrapper">
			<div class="header-block">
				<h2 class="header-block_title">Игры</h2>
				
				<router-link :to="{name: 'games'}" class="header-block_link">Все игры</router-link>
			</div>
			<div class="tab">
				<div class="tab_content">
					<div class="tab_block active">
						<slick v-if="games.length" :options="options" class="game-block">
							<div class="game-block_col" v-for="(gamesBlock, index) in games" :key="index + '-games'">
								<div class="game-block_item" v-for="game in gamesBlock" :key="game.title">
									<a :href="game.path" class="game-block_image" v-if="auth.subscribed">
										<img :src="$hostname + game.image_ref" alt="">
									</a>
									<router-link class="game-block_image" to="/subscribe" v-else>
										<img :src="$hostname + game.image_ref" alt="">
									</router-link>
									
									<a :href="game.path" class="game-block_title"  v-if="auth.subscribed">{{ game.title }}</a>
									<router-link class="game-block_title" to="/subscribe" v-else>{{ game.title }}</router-link>
								</div>
							</div>
						</slick>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Slick from 'vue-slick';
	
	export default {
		components: {
			Slick
		},
		
		data() {
			return {
				options: {
					arrows: false,
					variableWidth: true,
					autoplay: true,
					autoplaySpeed: 5000
				},
				dataGames: [],
				auth: window.auth
			}
		},
		
		computed: {
			games() {
				if (!this.dataGames) {
					return null;
				}
				
				let data = [];
				
				this.dataGames.forEach((game, index) => {
					if (index > 13) return;
					
					if (!data.length) {
						data.push([game]);
					} else if (data[data.length - 1].length < 2) {
						data[data.length - 1].push(game);
					} else {
						data[data.length] = [game];
					}
				});
				
				return data;
			}
		},
		
		created() {
			this.axios.get('/api/games').then(({data}) => {
				this.dataGames = data;
			});
		}
	}
</script>

<style scoped>

</style>