<template>
	<div>
		<div class="timeline_row" v-for="(incident, index) in incidents" :key="index">
			<div class="timeline_left">
				<div class="timeline_name" v-if="incident.team === 'localteam'">
					<div>
						{{ incident.player }}
						<div v-if="incident.comment">({{ getComment(incident.comment) }})</div>
					</div>
				</div>
				<div class="timeline_time" v-if="incident.team === 'awayteam'">{{ incident.min }}</div>
			</div>
			<div class="timeline_center">
				<div class="timeline_cart __yellow __card" v-if="incident.type === 'penalty'">2</div>
				<div class="timeline_ball __hockey" v-else-if="incident.type === 'goal'"></div>
			</div>
			<div class="timeline_right">
				<div class="timeline_name" v-if="incident.team === 'awayteam'">
					<div>
						{{ incident.player }}
						<div v-if="incident.comment">({{ getComment(incident.comment) }})</div>
					</div>
				</div>
				<div class="timeline_time" v-if="incident.team === 'localteam'">{{ incident.min }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['incidents'],
		
		methods: {
			getComment(comment) {
				let data = {
					"Powerplay": "Большинство",
					"Empty net": "Пустые ворота",
					"Shorthanded": "Меньшинство",
					"Roughing": "Грубость",
					"Hooking": "Задержка клюшкой",
					"Boarding": "Толчок на борт",
					"Delay of game": "Задержка игры",
					"Interference": "Блокировка",
					"Tripping": "Подножка",
					"Cross-checking": "Толчок клюшкой",
					"Holding": "Задержка",
					"High sticking": "Игра высоко поднятой клюшкой",
					"Slashing": "Удар клюшкой",
				};
				
				return data[comment] ? data[comment] : comment;
			}
		}
	}
</script>

<style scoped>

</style>