<template>
	<div class="field">
		<div class="field_row">
			<div class="field_col" v-for="(playersItems, playersName) in players" :key="playersName">
				<div class="field_players" v-for="(playersInner, key) in playersItems" :key="'players-inner-' + key">
					<div class="field_player" v-for="player in playersInner" :key="'player-' + player.id">
						<router-link :to="{name: 'player', params: {sport: 'soccer', id: player.id}}">
							{{ getPlayerName(player.name) }}
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		created() {
			
		},

		computed: {
			players() {
				return {
					localteam: this.initTeam(this.data.localteam),
					visitorteam: this.initTeam(this.data.visitorteam)
				};
			}
		},
		
		methods: {
			initTeam(team) {
				let formation = team.formation.split('-');
				formation.unshift('1');
				
				let data = {};
				
				let cutIndex = 0;

				formation.forEach((count, index) => {
					data[index] = team.player.slice(cutIndex, cutIndex = cutIndex + parseInt(count));
				});
				
				return data;
			},
			getPlayerName(fullname) {
				let data = fullname.split(' ');
				return data[data.length - 1];
			}
		}
	}
</script>

<style scoped>

</style>