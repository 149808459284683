<template>
	<div>
		<match-page-result-hockey></match-page-result-hockey>
	</div>
</template>

<script>
	import MatchPageResultHockey from "../components/Sports/Hockey/Pages/MatchPageResult";
	
	export default {
		components: {
			MatchPageResultHockey
		},
	}
</script>

<style scoped>

</style>