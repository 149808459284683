<template>
	<div>
		<header class="header">
			<div class="wrapper">
				<div class="header_row">
					<router-link :to="{name: 'home-base'}" class="header_name">
						<img src="/img/logo.svg" alt="">
					</router-link>
					
					<div class="header_right">
						<div class="header_ico" @click.prevent="showMenu = true">
							<i></i><i></i><i></i>
						</div>
					</div>
					
					<div class="header_menu" v-if="showMenu">
						<div class="header_menu-top">
							<div class="header_menu-prev" @click="changeMenu(prevMenu)" v-if="prevMenu.length"></div>
							<div class="header_menu-name"></div>
							<div class="header_menu-close" @click="showMenu = false"></div>
						</div>
						
						<div class="header_menu-data">
							<ul>
								<li v-if="!userLogged" class="header_menu-item">
									<a href="" @click.prevent="auth" class="a">
										<span>Войти</span>
									</a>
								</li>
								<li :class="{ '__has-children' : menuItem.children }"
									class="header_menu-item" v-for="menuItem in currentMenu" :key="menuItem.url">
									<div class="a"
										 :class="{ 'router-link-active' : menuItem.isActive, 'show-children': showChildren }"
										 v-if="menuItem.children" :href="menuItem.url">
										<span @click="toggleMenu">{{ menuItem.title }}</span>
										
										<transition name="slide">
											<ul v-show="showChildren">
												<li class="header_menu-item __children" v-for="childrenItem in menuItem.children" :key="childrenItem.url">
													<router-link :to="childrenItem.url" class="a">
														<span @click="showMenu = false">{{ childrenItem.title }}</span>
													</router-link>
												</li>
											</ul>
										</transition>
									</div>
									
									<router-link exact class="a" v-else-if="userSubscribed" :to="menuItem.url" :class="{ 'router-link-active' : menuItem.isActive }">
										<span @click="showMenu = false">{{ menuItem.title }}</span>
									</router-link>
									
									<router-link exact class="a" v-else-if="userLogged" :to="{name: 'subscribe'}">
										<span @click="showMenu = false">{{ menuItem.title }}</span>
									</router-link>
									
									<a href="" exact class="a" v-else @click.prevent="auth">
										<span @click="showMenu = false">{{ menuItem.title }}</span>
									</a>
								</li>
								
								<li v-if="userLogged" class="header_menu-item">
									<a href="" @click.prevent="logout" class="a">
										<span>Выйти</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
		
		<auth v-if="showForm" @hide="showForm = false"></auth>
	</div>
</template>

<script>
	import _ from 'underscore';

	import Auth from "../Auth/Auth";

	export default {
		components: {
			Auth
		},

		data() {
			return {
				menu: this.getDefaultData(),
				currentMenu: [],
				prevMenu: [],
				showMenu: false,
				showChildren: false,
				showForm: false,
				userLogged: window.auth,
				userSubscribed: window.subscribed
			}
		},


		created() {
			this.currentMenu = this.menu;

			this.showChildren = this.$route.name === 'home';

			window.events.$on('page-changed', () => {
				this.resetData();
			});

			window.events.$on('not-logged', () => {
				this.showForm = true;
			});

			window.events.$on('auth', () => {
				this.userLogged = window.auth;
			});

			window.events.$on('subscribed', () => {
				this.userSubscribed = window.subscribed;
			});
		},

		mounted() {
			$(document).on('click', 'a, .match-block_row', ((e) => {
				if (!$(e.target).closest('.header_menu').length || $(e.target).closest('.match-block_row').length) {
					this.showMenu = false;
				}
			}));
		},

		methods: {
			changeMenu(menu) {
				this.prevMenu = this.currentMenu;
				this.currentMenu = menu;

				if (this.currentMenu === this.menu) {
					this.prevMenu = [];
				}
			},
			resetData() {
				this.menu = this.getDefaultData();

				this.currentMenu = this.menu;

				this.showChildren = this.$route.name === 'home';
			},
			getDefaultData() {
				let matchIsActive = _.contains(['live-sport', 'event', 'tournament'], this.$route.name);

				return [
					{
						title: 'Виды спорта',
						url: '#',
						isActive: this.$route.name === 'home',

						children: [
							{title: 'Футбол', url: '/soccer'},
							{title: 'Теннис', url: '/tennis'},
							{title: 'Хоккей', url: '/hockey'},
							{title: 'Баскетбол', url: '/basketball'},
							//{title: 'Волейбол', url: '/volleyball'},
							{title: 'Бокс', url: '/box'},
							{title: 'ММА', url: '/mma'},
							//{title: 'Биатлон', url: '/biathlon'},
						]
					},

					{
						title: 'Матч центр',
						url: '/live',
						isActive: matchIsActive,
					},
					{
						title: 'Новости',
						url: '/news',
						isActive: this.$route.name === 'news' || this.$route.name === 'news-item',
					},
					{
						title: 'Статьи',
						url: '/articles',
						isActive: this.$route.name === 'articles' || this.$route.name === 'article',
					},
					{title: 'Видео', url: '/videos',},
					{title: 'Игры', url: '/games',},
				]
			},
			toggleMenu() {
				this.showChildren = !this.showChildren;
			},
			auth() {
				this.showForm = true;

				this.showMenu = false;
			},
			logout() {
				this.axios.get('/a1/logout')
					.then(({data}) => {
						location.reload();
					});
			}
		}
	}
</script>
