<template>
	<div>
		<div class="total-results">
			<div class="total-results_header">
				<div class="total-results_command">
					<img :src="$hostname + '/goalserve/hockey/team_image/' + match.home_id" alt="">
					<span>{{ match.home_name }}</span>
				</div>
				<div class="total-results_heading">Друг против друга</div>
				<div class="total-results_command">
					<span>{{ match.away_name }}</span>
					<img :src="$hostname + '/goalserve/hockey/team_image/' + match.away_id" alt="">
				</div>
			</div>
			
			<div class="total-results_content">
				<div class="total-results_info">{{ results.count }} - матчей сыграно</div>
				
				<div class="total-results_row">
					<div class="total-results_col">
						<div class="total-results_item __1">{{ results.team1Goals }}</div>
						<div class="total-results_text">Голы</div>
					</div>
					<div class="total-results_col">
						<div class="total-results_item __2" :class="{__transform: results.team1Wins > results.team2Wins}">
							{{ results.team1Wins }}
						</div>
						<div class="total-results_text">Победы</div>
					</div>
					<div class="total-results_col">
						<div class="total-results_item __3">
							{{ results.draw}}
						</div>
						<div class="total-results_text">Ничьи</div>
					</div>
					<div class="total-results_col">
						<div class="total-results_item __4" :class="{__transform: results.team2Wins > results.team1Wins}">
							{{ results.team2Wins }}
						</div>
						<div class="total-results_text">Победы</div>
					</div>
					<div class="total-results_col">
						<div class="total-results_item __5">{{ results.team2Goals }}</div>
						<div class="total-results_text">Голы</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['h2h', 'match'],
		
		computed: {
			results() {
				let data = {
					count: this.h2h.top50.match.length || 0,
					team1Goals: 0,
					team2Goals: 0,
					draw: 0,
					team1Wins: 0,
					team2Wins: 0
				};
				
				this.h2h.top50.match.forEach((item) => {
					if (item.team1_score === item.team2_score) {
						data.draw++;
					}
					
					if (! isNaN(Number(item.team1_score))) {
						data.team1Goals += Number(item.team1_score);
					}
					if (! isNaN(Number(item.team2_score))) {
						data.team2Goals += Number(item.team2_score);
					}
					
					if (Number(item.team1_score) > Number(item.team2_score)) {
						data.team1Wins++;
					}
					if (Number(item.team2_score) > Number(item.team1_score)) {
						data.team2Wins++;
					}
				});
				
				return data;
			}
		},
	}
</script>

<style scoped>

</style>