<template>
	<div>
		<div class="tickets">
			<div class="wrapper">
				<h1 class="tickets_heading">Билеты</h1>
				
				<div class="tab_header">
					<a href="" @click.prevent="changeType('Не задан')" :class="{ 'router-link-active': this.type === 'Не задан' }">Все</a>
					<a href="" @click.prevent="changeType('Футбол')" :class="{ 'router-link-active': this.type === 'Футбол' }">Футбол</a>
					<a href="" @click.prevent="changeType('Теннис')" :class="{ 'router-link-active': this.type === 'Теннис' }">Теннис</a>
					<a href="" @click.prevent="changeType('Хоккей')" :class="{ 'router-link-active': this.type === 'Хоккей' }">Хоккей</a>
					<a href="" @click.prevent="changeType('Баскетбол')" :class="{ 'router-link-active': this.type === 'Баскетбол' }">Баскетбол</a>
					<a href="" @click.prevent="changeType('Волейбол')" :class="{ 'router-link-active': this.type === 'Волейбол' }">Волейбол</a>
					<a href="" @click.prevent="changeType('Бокс')" :class="{ 'router-link-active': this.type === 'Бокс' }">Бокс</a>
					<a href="" @click.prevent="changeType('ММА')" :class="{ 'router-link-active': this.type === 'ММА' }">ММА</a>
					<a href="" @click.prevent="changeType('Биатлон')" :class="{ 'router-link-active': this.type === 'Биатлон' }">Биатлон</a>
				</div>
				
				<div class="tickets_filter">
					<div class="tickets_datepicker">
						<div class="tickets_date-select" @click="showDatepicker = !showDatepicker">{{ getDate }}</div>
						
						<div class="tickets_date-dropdown" v-show="showDatepicker">
							<datepicker-base @dateSelected="updateDate" :date="date"></datepicker-base>
						</div>
					</div>
				</div>
				
				<div class="tickets_data" v-if="filteredTickets.length">
					<div class="tickets_item" v-for="(ticket, index) in filteredTickets" :key="index + ticket.sessionid" v-show="ticket.show">
						<div class="tickets_images" v-if="ticket.hostclublogo && ticket.guestclublogo">
							<div class="tickets_image">
								<img :src="ticket.hostclublogo" alt="">
							</div>
							<span>vs</span>
							<div class="tickets_image">
								<img :src="ticket.guestclublogo" alt="">
							</div>
						</div>
						<div class="tickets_images" v-else>
							<div class="tickets_image">
								<img :src="ticket.thumbnail" alt="">
							</div>
						</div>
						
						<div class="tickets_title">{{ ticket.name }}</div>
						
						<div class="tickets_text">
							{{ getOutputDate(ticket.datetime) }},
							<br>
							{{ ticket.cityname }}, {{ ticket.address }}
						</div>
						
						<div class="tickets_price">от {{ ticket.minprice }} рублей</div>
						
						<a :href="'https://w.kassa.rambler.ru/event/' + ticket.sessionid + '/233f47e9-c7bb-4077-8020-5d2679af1e8f/%2f/'" class="tickets_button">Купить</a>
					</div>
				</div>
				<div class="tickets_error info-text" v-else-if="!this.initialized"></div>
				<div class="tickets_error" v-else>Билетов нет</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	
	import DatepickerBase from "../components/Datepicker/DatepickerBase";
	
	export default {
		components: {
			DatepickerBase
		},
		
		data() {
			return {
				tickets: [],
				type: 'Не задан',
				date: null,
				showDatepicker: false,
				initialized: false,
				page: 1,
				activeLoading: true
			}
		},
		computed: {
			filteredTickets() {
				var tickets;
				
				if (this.type === 'Не задан') {
					tickets = this.tickets.filter((item) => {
						if (this.date) 
							return this.getFormattedDate(new Date(item.datetime)) === this.date;
						
						return true;
					});

					return tickets.sort(function (a, b) {
						return new Date(a.datetime) - new Date(b.datetime);
					});
				}
				tickets =  this.tickets.filter((item) => {
					if (this.date)
						return item.sporttypename === this.type && this.getFormattedDate(new Date(item.datetime)) === this.date;
					
					return item.sporttypename === this.type;
				});
				
				return tickets.sort(function (a, b) {
					return new Date(a.datetime) - new Date(b.datetime);
				});
			},
			getDate() {
				if (!this.date) return 'Выбрать дату';
				
				return moment(this.date).locale('ru').format('D MMMM Y')
			}
		},
		created() {
			this.axios.get('/api/tickets').then(({data}) => {
				this.tickets = data;
				this.initialized = true;
				this.activeLoading = false;
				
				this.initTickets();
			});
			
			$(window).on('scroll', () => {
				if (window.isVisible($('.footer'))) {
					this.initTickets();
				}
			})
		},
		methods: {
			getFormattedDate(date) {
				var day = date.getDate();
				var month = date.getMonth() + 1;
				var year = date.getFullYear();

				if (month < 10) {
					month = '0' + month;
				}
				if (day < 10) {
					day = '0' + day;
				}

				return year + '-' + month + '-' + day;
			},
			
			updateDate(date) {
				this.date = this.getFormattedDate(date);
				
				this.tickets = this.tickets.filter((ticket, index) => {
					ticket.show = true;
					
					return true;
				});
				
				this.showDatepicker = false;
			},
			getOutputDate(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm')
			},
			changeType(type) {
				this.type = type;
				
				this.tickets = this.tickets.filter((ticket, index) => {
					ticket.show = true;
					
					return true;
				});
			},
			initTickets() {
				if (this.activeLoading) return false;
				
				this.activeLoading = true;
				
				this.tickets = this.tickets.filter((ticket, index) => {
					var count = this.page * 10;
					
					ticket.show = index < count;
					
					return true;
				});
				
				this.page++;
				this.activeLoading = false;
			}
		},
	}
</script>