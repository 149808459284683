<template>
	<div>
		<div class="match-tennis">
			<div class="match-tennis_date" v-if="match.status === 'Finished'">
				{{ getStatus(match.status) }}
			</div>
			<div class="match-tennis_date" v-else-if="match.status === 'Not Started' || match.status == '1'" v-text="match.time"></div>
			<div class="match-tennis_date __red" v-else v-html="getStatus(match.status)"></div>
			
			<div class="match-tennis_content">
				<div class="match_tennis_command" :class="{__bold: isWinner(home, away)}">
					<img :src="$hostname + '/goalserve/tennis/player/image?id=' + home.id" alt="">
					<span v-text="home.name"></span>
				</div>
				<div class="match_tennis_command" :class="{__bold: isWinner(away, home)}">
					<img :src="$hostname + '/goalserve/tennis/player/image?id=' + away.id" alt="">
					<span v-text="away.name"></span>
				</div>
			</div>
			
			<div class="match_tennis-result">
				<span v-text="home.totalscore"></span>
				<span v-text="away.totalscore"></span>
			</div>
			<div v-for="i in 5" :key="i">
				<div class="match-tennis_set" v-if="home['s' + i] || away['s' + i]">
					<span v-if="home['s' + i]" v-text="parseInt(home['s' + i])"></span>
					<span v-if="away['s' + i]" v-text="parseInt(away['s' + i])"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['match'],
		
		computed: {
			home() {
				return this.match.player[0]
			},
			away() {
				return this.match.player[1]
			},
		},
		methods: {
			getStatus(status) {
				let data = {
					'Finished': 'Завершен',
					'Not Started': 'Не нач.',
					'Set 1': 'Сет 1',
					'Set 2': 'Сет 2',
					'Set 3': 'Сет 3',
					'Set 4': 'Сет 4',
					'Set 5': 'Сет 5',
					'Retired': 'Завершен <br>(отказ)',
					'Walk Over': 'Неявка',
					'Cancelled': 'Отменен',
					'Break Time': 'Перерыв',
					'After Penalties': 'После буллитов',
					'After Over Time': 'После ОТ',
					'Interrupted': 'Прерван',
				};
				
				return data[status] ? data[status] : status;
			},
			isWinner(team1 ,team2) {
				return this.match.status === 'Finished' && team1.totalscore > team2.totalscore;
			}
		}
	}
</script>

<style scoped>

</style>