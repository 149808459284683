<template>
	<div>
		<div class="login-form" v-if="showLoginForm">
			<div class="login-form_title">Войти</div>
			<input v-model="tel" v-mask="'\\9\\92 (999) 999-999'" type="text" class="login-form_input" placeholder="992 (XXX) XXX - XXX">
			<a href="" class="login-form_button" @click.prevent="submitLogin">Получить пароль в СМС</a>
			<div class="login-form_close" @click="hideForm"></div>
			<div v-if="showLoginFormError" class="login-form_error">Ошибка. Номер введен не корректно или не принадлежит сети оператора ZET Mobile.</div>
			<div v-if="showLoginFormDefaultError" class="login-form_error">Ошибка. Повторите.</div>
		</div>
		
		<div class="login-form" v-else-if="showCodeForm">
			<div class="login-form_title">Введите код</div>
			<input v-model="tel" disabled type="text" class="login-form_input">
			<input v-model="code" type="text" name="code" id="code" class="login-form_input">
			<a href="" class="login-form_button" @click.prevent="submitCode">Войти</a>
			<div class="login-form_close" @click.prevent="hideForm"></div>
			<div v-if="showCodeFormError" class="login-form_error">Вы не правильно ввели проверочный код</div>
		</div>
		
		<div class="login-form-layout" @click="hideForm"></div>
	</div>
</template>

<script>
	import _ from 'underscore';
	
	export default {
		data() {
			return {
				showLoginForm: true,
				showLoginFormError: false,
				showCodeForm: false,
				showCodeFormError: false,
				showLoginFormDefaultError: false,
				tel: '',
				code: ''
			}
		},
		
		computed: {
			simpleTel() {
				let newTel = '';
				
				newTel = this.tel.replace(/-/g, '');
				newTel = newTel.replace(/\(/g, '');
				newTel = newTel.replace(/\)/g, '');
				newTel = newTel.replace(/ /g, '');
				
				return newTel;
			},
			firstTelNumbers() {
				return this.simpleTel.slice(0, 6);
			}
		},
		
		methods: {
			hideForm() {
				this.$emit('hide');
			},
			submitLogin() {
				this.showLoginFormError = false;
				this.showLoginFormDefaultError = false;
				
				if (this.checkTel()) {
					this.axios.get('/a1/send_auth_code?msisdn=' + this.simpleTel)
						.then(({data}) => {
							if (data.status === 'ok') {
								this.showLoginForm = false;
								this.showCodeForm = true;
							} else {
								this.showLoginFormDefaultError = true;
							}
						})
						.catch(() => {
							this.showLoginFormDefaultError = true;
						})
				} else {
					this.showLoginFormError = true;
				}
			},
			
			submitCode() {
				this.axios.get('/a1/check_auth_code?msisdn=' + this.simpleTel + '&code=' + this.code)
					.then(({data}) => {
						if (data.status === 'ok') {
							location.href = '/subscribe';
						} else {
							this.showCodeFormError = true;
						}
					});
			},
			
			checkTel() {
				let include = ['992919', '992917', '992915', '992911', '99240*', '99280*'];
				
				include.forEach((item, index) => {
					include[index] = item.replace('*', this.simpleTel[5]);
				})
				
				return this.simpleTel.length === 12 && _.contains(include, this.firstTelNumbers);
			}
		}
	}
</script>

<style scoped>

</style>
